import React from "react";

import { useGetTariffs, useGetTariffsById } from "@api/queries";
import { FilterTabType, TariffType } from "@common/Types";

import { CheckboxList } from "../checkbox-list";

import { CarIcon } from "./assets/icons";

type Props<T> = {
  filters: T;
  setFilters: (filters: T) => void;
  filterName: string;
};

const buildTariffsFilter = (parks: TariffType[]): FilterTabType[] => {
  return parks.map((park) => ({
    label: park.name,
    value: park.id.toString(),
  }));
};

export const TariffsFilter = <T extends { city_id?: string | number }>({
  filters,
  setFilters,
  filterName,
}: Props<T>) => {
  const selectedCity = filters.city_id;

  const { data: tariffs = [] } = useGetTariffs({ enabled: !selectedCity });
  const { data: tariffsByCity = [] } = useGetTariffsById({
    city_id: selectedCity as number,
    enabled: !!selectedCity,
  });

  const tariffList = buildTariffsFilter(selectedCity ? tariffsByCity : tariffs);

  return (
    <CheckboxList
      MainIcon={<CarIcon />}
      filters={filters}
      setFilters={setFilters}
      title={"Тариф"}
      field={filterName as keyof T}
      options={tariffList}
    />
  );
};
