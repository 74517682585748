import React from "react";

export const ExportIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3035 4.69687C18.2327 7.62604 18.2327 12.3744 15.3035 15.3035C12.3744 18.2327 7.62604 18.2327 4.69687 15.3035C1.76771 12.3744 1.76771 7.62604 4.69687 4.69687C7.62604 1.76771 12.3744 1.76771 15.3035 4.69687"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3035 4.69687C18.2327 7.62604 18.2327 12.3744 15.3035 15.3035C12.3744 18.2327 7.62604 18.2327 4.69687 15.3035C1.76771 12.3744 1.76771 7.62604 4.69687 4.69687C7.62604 1.76771 12.3744 1.76771 15.3035 4.69687"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0007 5.83203V11.6654"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6875 9.98047L10 11.668L8.3125 9.98047"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.05371 12.9453C8.68121 14.5728 11.3187 14.5728 12.9462 12.9453"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
