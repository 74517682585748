import React from "react";

export const NumberIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.25 16H4.25C3.698 16 3.25 15.552 3.25 15V9C3.25 8.448 3.698 8 4.25 8H20.25C20.802 8 21.25 8.448 21.25 9V15C21.25 15.552 20.802 16 20.25 16Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.015 11.7354C8.161 11.8814 8.161 12.1194 8.015 12.2654C7.869 12.4114 7.631 12.4114 7.485 12.2654C7.339 12.1194 7.339 11.8814 7.485 11.7354C7.631 11.5884 7.868 11.5884 8.015 11.7354"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.015 11.7354C12.161 11.8814 12.161 12.1194 12.015 12.2654C11.869 12.4114 11.631 12.4114 11.485 12.2654C11.339 12.1194 11.339 11.8814 11.485 11.7354C11.631 11.5884 11.869 11.5884 12.015 11.7354"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.015 11.7354C16.161 11.8814 16.161 12.1194 16.015 12.2654C15.869 12.4114 15.631 12.4114 15.485 12.2654C15.339 12.1194 15.339 11.8814 15.485 11.7354C15.631 11.5884 15.869 11.5884 16.015 11.7354"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
