import React from "react";

import { cn } from "@common/Utils";
import { Loader } from "@components/Elements";

import { NoResult, Pagination, Table } from "..";

import { Props } from "./types";

export const CustomTable = <T,>({
  data = [],
  dataIdKey = "id",
  loading,
  tableSchema,
  onRowClick,
  pagination,
}: Props<T>) => {
  return (
    <div className={"w-full flex flex-col"}>
      <Loader loading={loading} bordered height={400} className={"mt-0"}>
        {data?.length ? (
          <div className={"flex flex-col"}>
            <Table>
              <thead className="bg-white">
                <tr>
                  {tableSchema.map(
                    (row, index) =>
                      !row.isRemoved && (
                        <th
                          key={index}
                          className={cn(
                            `px-[16px] py-[14px] text-left !text-body-2-a border-neutral-border-200 font-medium text-neutral-text-500 ${row.thCellClassName}`,
                          )}
                        >
                          {row.header ? row.header() : row.title}
                        </th>
                      ),
                  )}
                </tr>
              </thead>

              <tbody className="divide-y divide-neutral-200 bg-transparent relative">
                {data?.map((item: T) => (
                  <tr
                    key={
                      item[dataIdKey as keyof T] as unknown as string | number
                    }
                    onClick={() => onRowClick?.(item)}
                    className={`transition-colors hover:bg-gray-50 bg-white ${
                      onRowClick ? "cursor-pointer" : ""
                    }`}
                  >
                    {tableSchema.map(
                      (row, index) =>
                        !row.isRemoved && (
                          <td
                            key={index}
                            className={cn(
                              `py-2 px-4 !text-body-2-b text-neutral-text-800 font-medium whitespace-nowrap truncate md:w-full ${row.tdCellClassName}`,
                            )}
                          >
                            {row.render
                              ? row.render(item)
                              : row.key
                              ? String(item[row.key] ?? "")
                              : null}
                          </td>
                        ),
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <NoResult className={"my-0"} />
        )}
      </Loader>

      <Pagination {...pagination} />
    </div>
  );
};
