import { Form, Formik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";

import { isTargetNavi } from "@assets/Flags";
import { ConfigsType, initialParams } from "@common/Types";
import {
  Block,
  FormExtendedSwitchField,
  FormSwitchField,
  Limits,
} from "@components/Shared";

import { ButtonActionLimits } from "../../components/shared/button-action-limits";
import { orderRouteParamsSchema } from "../validation";

type Props = {
  initialParams: initialParams;
  updateParams: (values: initialParams) => void;
  isBishkek: boolean;
};

export const OrderRouteParams = ({
  initialParams,
  updateParams,
  isBishkek,
}: Props) => {
  const [isEdit, setIsEdit] = useState(false);

  const onSubmitForm = async (values: initialParams) => {
    const loading_toast = toast.loading("Загрузка");

    try {
      await updateParams(values);
      setIsEdit(false);

      toast.success(`Лимиты успешно изменены`, {
        id: loading_toast,
      });
    } catch (error) {
      toast.remove(loading_toast);
    }
  };

  const onCloseForm = (callback: () => void) => {
    setIsEdit(false);
    callback();
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={() => orderRouteParamsSchema(isBishkek)}
      initialValues={initialParams}
      onSubmit={onSubmitForm}
    >
      {({ resetForm }) => {
        return (
          <Form>
            <Block
              title="Настройки заказа и маршрута"
              renderRightItem={
                <ButtonActionLimits
                  isEdit={isEdit}
                  onClose={() => onCloseForm(resetForm)}
                  onOpen={() => setIsEdit(true)}
                />
              }
            >
              <div className="space-y-6 pt-4">
                <Limits<ConfigsType>
                  title="Круг распределения заказов"
                  isEdit={isEdit}
                  params={[
                    {
                      name: "Количество использований",
                      field: "offer_iter_limit",
                    },
                    {
                      name: "Секунд",
                      field: "offer_iter_limit_timeout",
                    },
                    {
                      name: "Время поступления заказа (сек)",
                      field: "offer_expiration",
                    },
                  ]}
                />

                <Limits<ConfigsType>
                  title="Радиус распределения заказов (в метрах)"
                  isEdit={isEdit}
                  params={[
                    {
                      name: "Базовый",
                      field: "regular_rides_search_radius",
                    },
                    {
                      name: "По завершению",
                      field: "rides_by_finish_search_radius",
                    },
                    {
                      name: "Домой",
                      field: "to_home_search_radius",
                    },
                    {
                      name: "По делам",
                      field: "to_business_search_radius",
                    },
                  ]}
                />

                <Limits<ConfigsType>
                  title="Функция “Домой”"
                  isEdit={isEdit}
                  params={[
                    {
                      name: "Кол-во поездок “Домой”",
                      field: "to_home_limitation_per_day",
                    },
                    {
                      disabled: true,
                      name: "Сброс услуги “Домой” (в днях)",
                      field: "perDay",
                    },
                    {
                      name: "Кол-во смен адреса “Домой”",
                      field: "change_home_points_limitation",
                    },
                    {
                      name: "Сброс смен адреса (в днях)",
                      field: "change_home_points_days_limitation_in_days",
                    },
                  ]}
                />

                <Limits<ConfigsType>
                  title="Функция “По делам”"
                  isEdit={isEdit}
                  params={[
                    {
                      name: "Кол-во поездок “По делам”",
                      field: "on_business_limitation_per_day",
                    },
                    {
                      disabled: true,
                      name: "Сброс услуги “По делам” (в днях)",
                      field: "perDay",
                    },
                  ]}
                />

                <FormSwitchField<ConfigsType>
                  isEdit={isEdit}
                  params={[
                    {
                      name: "Альтернативная цена посадки",
                      field: "ya_prices_enabled",
                    },
                    ...(isTargetNavi
                      ? [
                          {
                            name: "Минимальная цена альтернативной посадки",
                            field: "adjust_ya_prices" as keyof ConfigsType,
                          },
                          {
                            name: "Округление цен альтернативной посадки",
                            field: "round_ya_prices" as keyof ConfigsType,
                          },
                        ]
                      : []),
                  ]}
                />

                {!isTargetNavi && (
                  <FormExtendedSwitchField<ConfigsType>
                    name="Альтернативное ценообразование поездки"
                    field="ya_v2_prices_enabled"
                    isEdit={isEdit}
                    params={{
                      name: "Снижение от базовой цены",
                      field: "ya_v2_prices_percentage",
                      symbol: "%",
                    }}
                  />
                )}

                {isBishkek && (
                  <FormExtendedSwitchField<ConfigsType>
                    name="Интеграция с Jorgo такси"
                    field="use_jorgo"
                    isEdit={isEdit}
                    params={{
                      name: "Секунды для редиректа",
                      field: "redirect_to_jorgo_seconds",
                    }}
                  />
                )}
              </div>
            </Block>
          </Form>
        );
      }}
    </Formik>
  );
};
