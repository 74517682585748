import { useGetPermissions } from "@api/queries";
import { isTargetNavi } from "@assets/Flags";
import {
  PERMISSIONS_MOTOR,
  PERMISSIONS_NAVI,
  ROLES_MOTOR,
  ROLES_NAVI,
  ROUTES,
  ROUTES_RENDER_KEY,
} from "@common/Constants";
import { RoleType } from "@common/Types";

type KeyType = keyof typeof ROUTES_RENDER_KEY;

/**
 * Custom hook to get filtered routes based on the provided role.
 *
 * @param {Role} role - The role of the user, which determines which permissions and routes are used.
 * @returns {Array} - The filtered routes based on the active permissions for the given role.
 */

export const useGetRoutes = (role: RoleType) => {
  let activePages = [];
  const isAdmin =
    role === ROLES_NAVI.NAVIADMIN || role === ROLES_MOTOR.MOTORADMIN;

  const { response = [], isLoading } = useGetPermissions({ enabled: isAdmin });

  if (isAdmin) {
    const activeKeys = Object.keys(ROUTES_RENDER_KEY).filter((key) =>
      response.some(
        (item) =>
          item.is_active && item.name === ROUTES_RENDER_KEY[key as KeyType],
      ),
    );

    activePages = activeKeys.map((key) => ROUTES[key as keyof typeof ROUTES]);
  } else {
    activePages = isTargetNavi
      ? PERMISSIONS_NAVI[role]
      : PERMISSIONS_MOTOR[role];
  }

  return { routes: activePages, isLoading };
};
