import qs from "query-string";
import { chain, isEmpty } from "radash";

export const commaSerializer = <T extends Record<string, any>>(filters: T) => {
  return qs.stringify(filters, { arrayFormat: "comma" });
};

export const defaultSerializer = <T extends Record<string, any>>(
  filters: T,
) => {
  return qs.stringify(filters);
};

export const noneSerializer = <T extends Record<string, any>>(filters: T) => {
  return qs.stringify(filters, { arrayFormat: "none" });
};

//  API helpers
export const removeEmptyFilterValues = (
  filters: Record<string, any> | null | undefined,
): Record<string, any> | null | undefined => {
  if (!filters) return filters;

  return Object.fromEntries(
    Object.entries(filters).filter(
      ([_, value]) => typeof value === "boolean" || !isEmpty(value),
    ),
  );
};

export const removeFilterByKeyValue = <T extends object>(
  keyToBeRemoved: keyof T,
  value: T[keyof T],
  filters: T,
): T => {
  return Object.keys(filters).reduce((acc, currentKey) => {
    const typedKey = currentKey as keyof T;
    if (typedKey === keyToBeRemoved && filters[typedKey] === value) {
      return acc;
    }
    acc[typedKey] = filters[typedKey];
    return acc;
  }, {} as T);
};

export const removeEmptyFields = <T extends object>(values: T): T => {
  return Object.keys(values).reduce((acc, currentKey) => {
    const key = currentKey as keyof T;
    const isEmptyValues = !isEmpty(values[key]); // not empty

    if (isEmptyValues) {
      acc[key] = values[key];
    }
    return acc;
  }, {} as T);
};

export const buildCommonFiltersValues = <T>(filters: T): Partial<T> => {
  const chained = chain(removeEmptyFilterValues);
  return chained(filters);
};
