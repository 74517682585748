import * as Yup from "yup";

export const confirmModalWithReasonValidationSchema = (activated: boolean) =>
  Yup.object().shape({
    comment: Yup.string().when([], {
      is: () => activated,
      then: (schema) =>
        schema
          .required("Обязательное поле")
          .max(500, "Описание не должно превышать 500 символов"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
