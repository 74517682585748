import { TOKEN } from "@common/Constants";

import { LocalStorageReturnType } from "./user-localstorage";

export const getAccessTokenLocalStorage = (): LocalStorageReturnType => {
  return localStorage.getItem(TOKEN.ACCESS_TOKEN);
};

export const getRefreshTokenLocalStorage = (): LocalStorageReturnType => {
  return localStorage.getItem(TOKEN.REFRESH_TOKEN);
};

export const removeToken = (): void => {
  localStorage.removeItem(TOKEN.ACCESS_TOKEN);
};

export const removeRefreshToken = (): void => {
  localStorage.removeItem(TOKEN.REFRESH_TOKEN);
};

export const setTokenLocalStorage = (token: string): void => {
  localStorage.setItem(TOKEN.ACCESS_TOKEN, token);
};

export const setRefreshTokenLocalStorage = (refreshToken: string): void => {
  localStorage.setItem(TOKEN.REFRESH_TOKEN, refreshToken);
};
