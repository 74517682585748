import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { driversService } from "@api/services";
import { StatisticsQueryParams } from "@common/Types";

type Params = StatisticsQueryParams & {
  enabled?: boolean;
};

export const useGetDriverRideStatistics = ({
  enabled,
  ...params
}: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.DRIVERS_RIDE_STATISTICS, params],
    queryFn: () => driversService.driverRideStatistics(params),
    enabled,
  });
};
