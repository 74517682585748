import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { driversService } from "@api/services";
import { IDType } from "@common/Types";

export const useSendPhotoControl = () => {
  return useMutation({
    mutationFn: async (driver_ids: IDType[]) =>
      await driversService.sendPhotoControl(driver_ids),

    onSuccess: (_, variables) => {
      if (variables.length > 1) {
        return toast.success("Отправлены на фотоконтроль");
      }

      return toast.success("Отправлен на фотоконтроль");
    },
  });
};
