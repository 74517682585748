import * as Yup from "yup";

import {
  booleanNumberValidation,
  getDynamicPercentValidation,
  intervalValidation,
  numberValidation,
  offerExpirationValidation,
  quantityLimitValidation,
} from "@common/Validation";

export const orderRouteParamsSchema = (isBishkek: boolean) =>
  Yup.object().shape({
    offer_expiration: offerExpirationValidation,
    offer_iter_limit: quantityLimitValidation,
    offer_iter_limit_timeout: intervalValidation,

    regular_rides_search_radius: numberValidation,
    rides_by_finish_search_radius: numberValidation,
    to_home_search_radius: numberValidation,
    to_business_search_radius: numberValidation,

    to_home_limitation_per_day: numberValidation,
    change_home_points_limitation: numberValidation,
    change_home_points_days_limitation_in_days: numberValidation,

    ya_prices_enabled: booleanNumberValidation,
    adjust_ya_prices: booleanNumberValidation,
    round_ya_prices: booleanNumberValidation,
    ya_v2_prices_enabled: booleanNumberValidation,
    ya_v2_prices_percentage: getDynamicPercentValidation(),

    use_jorgo: booleanNumberValidation,
    redirect_to_jorgo_seconds: numberValidation.when([], (_, schema) =>
      isBishkek ? schema.required() : schema.notRequired(),
    ),
  });

export const driverParamsSchema = Yup.object().shape({
  fees: Yup.array().of(
    Yup.object().shape({
      percentage: getDynamicPercentValidation(),
    }),
  ),

  zero_commission_enabled: booleanNumberValidation,
  zero_commission_minimum: numberValidation,

  driver_ride_ignore_limit_enabled: booleanNumberValidation,
  driver_ride_ignore_limit_number: numberValidation,
  driver_ride_ignore_block_minutes: numberValidation,
  show_ride_points_in_driver_offer: booleanNumberValidation,
  show_ride_estimation_in_driver_offer: booleanNumberValidation,
});

export const withdrawCommissionParamsSchema = Yup.object().shape({
  daily_withdraw_count_limit: numberValidation,
  withdraw_min_amount: numberValidation,
  daily_withdraw_sum_limit: numberValidation,
  withdraw_driver_funds_by_mbank: booleanNumberValidation,

  fees: Yup.array().of(
    Yup.object().shape({
      fixed_fee: getDynamicPercentValidation(0, 9999999999),
      min_fee: getDynamicPercentValidation(0, 9999999999),
      percentage: getDynamicPercentValidation(),
    }),
  ),
});

export const riderParamsSchema = Yup.object().shape({
  max_bonus_payment_percentage: getDynamicPercentValidation(0, 50),

  save_card_visa: booleanNumberValidation,
  create_ride_by_visa: booleanNumberValidation,
  create_ride_by_kaspi: booleanNumberValidation,
});
