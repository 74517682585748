import { FeesPartnerType } from "@common/Types";

export const WithdrawFormSwitchFields: Partial<
  Record<FeesPartnerType, string>
> = {
  mbank: "withdraw_driver_funds_by_mbank",
  epay: "withdraw_driver_funds_by_epay",
  altyn: "withdraw_driver_funds_by_altyn",
  freedom: "withdraw_driver_funds_by_freedom",
};
