import React from "react";

export const StarIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 2.89453L15.435 8.88753L22 9.85453L17.25 14.5165L18.371 21.1025L12.5 17.9915L6.629 21.1025L7.75 14.5165L3 9.85453L9.564 8.88753L12.5 2.89453Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
