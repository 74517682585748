import { appConfig } from "@api/config";

/**
 * Сервисы для permission
 */

const src = appConfig.api.dashboard;
const permissionQuery = `${src}/dispatcher/permission/`;

export const permissionsPath = {
  permission: permissionQuery,
};
