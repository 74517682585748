import React from "react";

import { DRIVER_STATUS } from "@common/Constants";

import { CheckboxList } from "../checkbox-list";

import { DriverStatusIcon } from "./assets/icons";

type Props<T> = {
  filters: T;
  setFilters: (filters: T) => void;
};

export const DriverStatusFilter = <T,>({ filters, setFilters }: Props<T>) => {
  return (
    <CheckboxList
      MainIcon={<DriverStatusIcon />}
      title={"Статус"}
      filters={filters}
      setFilters={setFilters}
      field={"status" as keyof T}
      options={DRIVER_STATUS}
    />
  );
};
