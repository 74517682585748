import { isTargetNavi } from "@assets/Flags";
import { FilterTabType } from "@common/Types";

export type SelectOptionType = {
  label: string;
  value: string | number;
};

export const CAR_COLORS: FilterTabType[] = [
  { value: "Красный", label: "Красный" },
  { value: "Бордовый", label: "Бордовый" },
  { value: "Синий", label: "Синий" },
  { value: "Голубой", label: "Голубой" },
  { value: "Зеленый", label: "Зеленый" },
  { value: "Коричневый", label: "Коричневый" },
  { value: "Бежевый", label: "Бежевый" },
  { value: "Розовый", label: "Розовый" },
  { value: "Фиолетовый", label: "Фиолетовый" },
  { value: "Серый", label: "Серый" },
  { value: "Серебристый", label: "Серебристый" },
  { value: "Желтый", label: "Желтый" },
  { value: "Оранжевый", label: "Оранжевый" },
  { value: "Золотистый", label: "Золотистый" },
  { value: "Черный", label: "Черный" },
  { value: "Белый", label: "Белый" },
];

export const USER_ROLES: FilterTabType[] = [
  {
    value: "dispatcher",
    label: "Администратор-диспетчер",
  },
  { value: "citysuperadmin", label: "Администратор города" },
  { value: "parkadmin", label: "Администратор таксопарка" },
];

export const STATUS: FilterTabType[] = [
  { label: "В работе", value: "ride_in_progress" },
  { label: "В поиске", value: "searching_for_driver" },
  { label: "Выполненные", value: "ride_completed" },
  { label: "Водитель не найден", value: "driver_not_found" },
  { label: "Отменен пассажиром", value: "rider_cancelled" },
  { label: "Отменен администратором", value: "admin_cancelled" },
  {
    label: "Отменен пассажиром во время поездки",
    value: "rider_cancelled_on_ride",
  },
];

export const RIDE_EVENT_STATISTICS: FilterTabType[] = [
  { label: "Поездка запрошена", value: "ride_requested" },
  { label: "Поиск водителя", value: "searching_driver" },
  { label: "Водитель найден", value: "driver_found" },
  { label: "Водитель не найден", value: "driver_not_found" },
  { label: "Предложен заказ водителю", value: "offered_to_driver" },
  { label: "Водитель получил заказ", value: "driver_received" },
  { label: "Водитель принял заказ", value: "driver_accepted" },
  { label: "Поездка не завершена", value: "not_completed" },
  { label: "Не обслуживается", value: "not_served" },
  { label: "Перерасчет стоимости поездки", value: "ride_price_recalculated" },
  { label: "Поездка завершена", value: "driver_completed" },
  { label: "Платное ожидание закончилось", value: "wait_ended" },
  { label: "Платное ожидание началось", value: "wait_started" },
  { label: "Ожидание закончилось", value: "pre_ride_wait_ended" },
  { label: "Ожидание началось", value: "pre_ride_wait_started" },
  { label: "Пассажир изменил направление", value: "rider_changed_destination" },
  { label: "Поездка началась", value: "ride_started_for_rider" },
  { label: "От борта", value: "driver_pickup" },
  { label: "Пассажир отменил заказ", value: "rider_cancelled" },
  { label: "Водитель отменил заказ", value: "driver_cancelled" },
  { label: "Водитель ждет пассажира", value: "driver_expecting_rider" },
  { label: "Водитель отклонил заказ", value: "driver_declined" },
  { label: "Водитель пропустил заказ", value: "driver_missed" },
  { label: "Заказ поступил", value: "ride_offer_received" },
  { label: "Ошибка отправки статуса поездки", value: "sse_status_fail" },
  { label: "Статус поездки успешно отправлен", value: "sse_status_success" },
  { label: "Отменен администратором", value: "admin_cancelled" },
  { label: "Поездка успешно предложена SSE", value: "sse_offer_success" },
  { label: "Ошибка в предложении поездки SSE", value: "sse_offer_fail" },
  { label: "Заказ показан", value: "ride_offer_shown" },
  { label: "Поездка успешно предложена WS", value: "ws_offer_success" },
  { label: "Ошибка в предложении поездки WS", value: "ws_offer_fail" },
  {
    label: "Водитель взял заказ по завершению",
    value: "driver_accepted_as_by_finish",
  },
  {
    label: "Водитель завершил предыдущую поездку",
    value: "driver_completed_previous_ride",
  },
  { label: "Был изменен способ оплаты", value: "payment_type_changed" },
];

export const RIDE_EVENT_TYPE: FilterTabType[] = [
  { label: "Домой", value: "home" },
  { label: "По делам", value: "business" },
];

export const DRIVER_STATUS: FilterTabType[] = [
  { label: "На линии", value: "online" },
  { label: "Оффлайн", value: "offline" },
  { label: "Занят", value: "busy" },
  { label: "В пути", value: "on_ride" },
];

export const BALANCE: FilterTabType[] = [
  { label: "Списание", value: "withdraw" },
  { label: "Пополнение", value: "replenishment" },
];

export const SUBSCRIBERS: FilterTabType[] = [
  { label: "Всем", value: "all" },
  { label: "Водителям", value: "drivers" },
  { label: "Пассажирам", value: "riders" },
];

export const DRIVERS_STATUS: FilterTabType[] = [
  { label: "Активные", value: "true", accessKey: "drivers_active" },
  { label: "Заблокированные", value: "false", accessKey: "drivers_blocked" },
];

export const DRIVERS_STATISTICS_STATUSES: FilterTabType[] = [
  { label: "Активные", value: "true" },
  { label: "Завершенные", value: "false" },
];

export const RIDERS_STATUS: FilterTabType[] = [
  { label: "Активные", value: "false" },
  { label: "Заблокированные", value: "true" },
];

export const PAYMENT_FILTERS_MOTOR: FilterTabType[] = [
  { label: "Все", value: "all" },
  { label: "Наличные", value: "cash" },
  { label: "Картой", value: "epay" },
  { label: "Каспи", value: "kaspi" }, 
  { label: "Корпоративный", value: "corporate" },
  { label: "Семейный", value: "family" },
];

export const PAYMENT_FILTERS_NAVI: FilterTabType[] = [
  { label: "Все", value: "all" },
  { label: "Наличные", value: "cash" },
  { label: "Картой", value: "payler" },
  { label: "Мбанк", value: "mbank" }, 
  { label: "Корпоративный", value: "corporate" },
  { label: "Семейный", value: "family" },
];

export const PAYMENT_FILTERS: FilterTabType[] = isTargetNavi
  ? PAYMENT_FILTERS_NAVI
  : PAYMENT_FILTERS_MOTOR;

export const AUDIENCE_FILTERS: FilterTabType[] = [
  { label: "Все", value: "all" },
  { label: "Водители", value: "driver" },
  { label: "Пассажиры", value: "rider" },
];

export const DRIVER_APPLICATION_FILTERS: FilterTabType[] = [
  {
    label: "Новые",
    value: "new",
    accessKey: "driver_registration_new_application",
  },
  {
    label: "Отклоненные",
    value: "rejected",
    accessKey: "driver_registration_declined_application",
  },
  {
    label: "Одобренные",
    value: "approved",
    accessKey: "driver_registration_approved_application",
  },
];

export const DRIVER_APPLICATION_CAR_CHANGE_FILTERS: FilterTabType[] = [
  {
    label: "Новые",
    value: "new",
    accessKey: "driver_auto_new_application",
  },
  {
    label: "Отклоненные",
    value: "rejected",
    accessKey: "driver_auto_declined_application",
  },
  {
    label: "Одобренные",
    value: "approved",
    accessKey: "driver_auto_approved_application",
  },
];

export const FRAUD_CONTROL_FILTERS: FilterTabType[] = [
  { label: "На рассмотрении", value: "in_moderation" },
  { label: "Одобренные", value: "unlocked" },
  { label: "Отклоненные", value: "blocked" },
];

export const DRIVER_CAR_CLASS: FilterTabType[] = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
];

export const SUBSCRIBER_FILTERS = [
  { label: "Общие", value: "all" },
  { label: "Водителям", value: "drivers" },
  { label: "Пассажирам", value: "riders" },
];

export const DRIVER_STATUSES_FILTER: FilterTabType[] = [
  { label: "Использование функции", value: "in_progress" },
  { label: "Завершение функции", value: "finished" },
  { label: "Поездка не найдена", value: "not_found" },
  { label: "Новая", value: "new" },
  { label: "Поиск водителя", value: "searching_for_driver" },
  { label: "Водитель найден", value: "driver_found" },
  { label: "Предложен водителю", value: "offered_ride_to_driver" },
  { label: "Водитель не найден", value: "driver_not_found" },
  { label: "Водитель подъезжает", value: "driver_coming" },
  { label: "Ожидается пассажир", value: "expecting_rider" },
  { label: "Отменен пассажиром", value: "rider_cancelled" },
  { label: "Поездка в процессе", value: "ride_in_progress" },
  { label: "Поездка на ожидании", value: "ride_on_hold" },
  { label: "Поездка завершена", value: "ride_completed" },
];

export const TRANSACTION_HISTORY_STATUSES: FilterTabType[] = [
  { value: "block", label: "Деактивация" },
  { value: "unblock", label: "Активация" },
  { value: "refund", label: "Возврат" },
  { value: "debt", label: "Долг" },
  { value: "bonuses", label: "Бонусы" },
];

export const activation: FilterTabType[] = [
  { value: "activation", label: "Активный" },
  { value: "deactivation", label: "Заблокированный" },
];

export const TRANSACTION_TYPE: FilterTabType[] = [
  { label: "Списание с баланса", value: "balance_withdraw" },
  { label: "Пополнение баланса", value: "balance_add" },
  { label: "Списание бонусов", value: "bonus_withdraw" },
  { label: "Пополнение бонусов", value: "bonuses_add" },
];
