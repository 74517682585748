import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { ridersService } from "@api/services";

type Params = {
  id: string;
};

export const useGetFamilyAccountDetail = ({ id }: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.FAMILY_ACCOUNT_DETAIL, id],
    queryFn: async () => await ridersService.getFamilyAccountDetail(id),
  });
};
