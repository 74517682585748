import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { driversService } from "@api/services";
import { DriversLocationType, DriverTypes } from "@common/Types";

type Params = DriversLocationType & {
  refetchInterval?: number;
};

export const useGetDriversLocation = ({
  refetchInterval,
  ...filters
}: Params) => {
  return useQuery<DriverTypes>({
    queryKey: [QUERY_KEYS.DRIVERS_LOCATION, filters],
    queryFn: async () => await driversService.getDriverLocation(filters),
    refetchInterval,
  });
};
