import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { citiesService } from "@api/services";

export const useGetAdminCity = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.CITIES_ADMIN],
    queryFn: async () => await citiesService.getAdminCities(),
  });
};
