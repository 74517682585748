import React from "react";

import { SVGProps } from "@common/Types";

type Props = SVGProps;

export const CameraRotateIcon = ({ size = 20 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 13L16 14L15 13" fill="#888888" />
      <path
        d="M17 13L16 14L15 13"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.892 13.892C15.958 13.604 16 13.308 16 13C16 10.791 14.209 9 12 9C11.151 9 10.367 9.268 9.71899 9.719"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 9V18C21 19.105 20.105 20 19 20H5C3.895 20 3 19.105 3 18V9C3 7.895 3.895 7 5 7H7L8.462 4.496C8.641 4.189 8.97 4 9.326 4H14.63C14.981 4 15.306 4.184 15.487 4.484L17 7H19C20.105 7 21 7.895 21 9Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 13L8 12L9 13"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.108 12.1094C8.042 12.3974 8 12.6934 8 13.0014C8 15.2104 9.791 17.0014 12 17.0014C12.849 17.0014 13.633 16.7334 14.281 16.2824"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
