import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { driversService } from "@api/services";
import { initialDriverType } from "@common/Types";

export const useUpdateDriver = () => {
  return useMutation({
    mutationFn: (driver: initialDriverType) =>
      driversService.updateDriver(driver),
    onSuccess: () => toast.success("Профиль водителя успешно обновлен"),
  });
};
