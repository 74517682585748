const buildEndpoint = (name: string) => {
  return `/api/v1/${name}`;
};

export const endpoints = {
  auth: "auth",
  landing: "landing",
  dashboard: "dashboard",
  drivers: "drivers",
  antifraud: "antifraud",
  users: "users",
  cards: "cards",
  promotions: "promotions",
  tariffs: "tariffs",
  images: "image",
  reports: "reports",
  family_account: "family-accounts",
};

type EndpointKeys = keyof typeof endpoints;

type Api = Record<EndpointKeys, string>;

const init = (endpoints: Api) => {
  let _api = {};
  for (const key in endpoints) {
    _api = { ..._api, [key]: buildEndpoint(endpoints[key as EndpointKeys]) };
  }
  return _api as Api;
};

class AppConfig {
  private _api: Api;
  constructor() {
    this._api = init(endpoints);
  }

  get api(): Api {
    return this._api;
  }
}

const appConfig = new AppConfig();

export { appConfig };
