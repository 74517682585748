import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { TransactionsService } from "@api/services";
import { TransactionsParams } from "@common/Types";

export const useGetTransactions = ({
  filters,
  skip,
  limit,
  refetchInterval,
}: TransactionsParams) => {
  return useQuery({
    queryKey: [QUERY_KEYS.TRANSACTIONS, filters, skip, limit],
    queryFn: async () =>
      await TransactionsService.getTransactions({ filters, skip, limit }),
    refetchInterval,
  });
};
