import { Form, Formik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";

import { initialParams, ParkSettingsType } from "@common/Types";
import { Block, FormSwitchField } from "@components/Shared";

import { ButtonActionLimits } from "../../components/shared/button-action-limits";
import { WithdrawLimits } from "../../components/shared/withdraw-limits";
import { parkParamsSchema } from "../validation";

type Props = {
  initialParams: initialParams;
  updateParams: (values: initialParams) => void;
};

export const ParkParams = ({ initialParams, updateParams }: Props) => {
  const [isEdit, setIsEdit] = useState(false);

  const onSubmitForm = async (values: initialParams) => {
    const loading_toast = toast.loading("Загрузка");

    try {
      await updateParams(values);
      setIsEdit(false);

      toast.success(`Лимиты успешно изменены`, {
        id: loading_toast,
      });
    } catch (error) {
      toast.remove(loading_toast);
    }
  };

  const onCloseForm = (callback: () => void) => {
    setIsEdit(false);
    callback();
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={parkParamsSchema}
      initialValues={initialParams}
      onSubmit={onSubmitForm}
    >
      {({ resetForm }) => {
        return (
          <Form>
            <Block
              title="Настройки заказа и маршрута"
              renderRightItem={
                <ButtonActionLimits
                  isEdit={isEdit}
                  onClose={() => onCloseForm(resetForm)}
                  onOpen={() => setIsEdit(true)}
                />
              }
            >
              <div className="space-y-6 pt-4">
                <FormSwitchField<ParkSettingsType>
                  isEdit={isEdit}
                  params={{
                    name: "Использовать настройки города",
                    field: "use_city_configs",
                  }}
                />

                <WithdrawLimits
                  type="ride_payment"
                  configs={initialParams.fees}
                  isEdit={isEdit}
                />
              </div>
            </Block>
          </Form>
        );
      }}
    </Formik>
  );
};
