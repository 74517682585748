import { Checkbox as MantineCheckbox } from "@mantine/core";
import React from "react";

import { Props } from "./types";
import { VIEW_CLASSES } from "./variants";

export const Checkbox = ({ view = "brand", ...props }: Props) => {
  return (
    <MantineCheckbox
      classNames={{ icon: "opacity-0", input: VIEW_CLASSES[view] }}
      {...props}
    />
  );
};
