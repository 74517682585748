/**
 * Сервисы для transactions
 */

import { appConfig } from "@api/config";

const src = appConfig.api.dashboard;

export const transactionsPath = {
  transactions: `${src}/drivers/transactions/`,
  reports: `${src}/drivers/transactions/report/`,
};
