import { chain } from "radash";

import {
  buildDateRangeFromTextRange,
  removeEmptyFilterValues,
  removeFilterByKeyValue,
} from "@common/Utils";

export const buildGetRiderRidesFilterValues = <T>(filters: T): Partial<T> => {
  const chained = chain(
    buildDateRangeFromTextRange("select_range"),
    (filters) => removeFilterByKeyValue("payment_type", "all", filters),
    removeEmptyFilterValues,
  );
  return chained(filters);
};
