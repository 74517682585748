import React from "react";

import { SVGProps } from "@common/Types";

type Props = SVGProps & {
  type: "up" | "down";
};

export const TrendIcon = ({ type, ...rest }: Props) => {
  switch (type) {
    case "down": {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...rest}
        >
          <path
            d="M19.3125 17.0625L12.5625 10.3125L9.75 13.125L4.6875 8.0625"
            stroke="#D33333"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.3125 12.5625V17.0625H14.8125"
            stroke="#D33333"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case "up": {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...rest}
        >
          <path
            d="M19.3125 6.9375L12.5625 13.6875L9.75 10.875L4.6875 15.9375"
            stroke="#13B633"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.3125 11.4375V6.9375H14.8125"
            stroke="#13B633"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
  }
};
