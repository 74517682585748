import { Tabs as MantineTabs } from "@mantine/core";
import React from "react";

import { useTabControl } from "@common/Hooks";
import { TabsType } from "@common/Types";

import { TABS_VIEW_CLASSES } from "./variants";

type Props<T> = {
  tabs: TabsType<T>[];
  children: (tab: TabsType<T>) => React.ReactNode;
  onChange?: (value: string) => void;
  activeIndex?: number;
  view?: string;
};

export const Tabs = <T extends string>({
  tabs,
  children,
  onChange,
  activeIndex = 0,
  view = "default",
}: Props<T>) => {
  const { activeTab, setActiveTab } = useTabControl(activeIndex);

  const onTabChange = (tabIndex: number, tabKey: string) => {
    setActiveTab(tabIndex);
    onChange?.(tabKey);
  };

  return (
    <MantineTabs
      active={activeTab}
      onTabChange={onTabChange}
      classNames={TABS_VIEW_CLASSES[view]}
    >
      {tabs.map((tab, index) => (
        <MantineTabs.Tab key={index} label={tab.label} tabKey={tab.value}>
          {children(tab)}
        </MantineTabs.Tab>
      ))}
    </MantineTabs>
  );
};
