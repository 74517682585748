import { httpClient } from "@common/Libs/axios";
import {
  TransactionMainType,
  TransactionsFiltersType,
  TransactionsParams,
} from "@common/Types";
import {
  buildCommonDateFilters,
  buildDateRangeFiltersValues,
  buildDateRangeFromTextRange,
  commaSerializer,
} from "@common/Utils";

import { transactionsPath } from "./paths";

/**
 * Описание методов transactions.
 */

export const TransactionsService = {
  getTransactions: ({ filters, skip, limit }: TransactionsParams) => {
    const filteredDate = buildCommonDateFilters(filters, [
      "date_range",
      "city_id",
      "type",
      "select_range",
      "search",
    ]);

    return httpClient.get<TransactionMainType>(transactionsPath.transactions, {
      params: { ...filteredDate, skip, limit },
      paramsSerializer: commaSerializer,
    });
  },
  getDriverTransactionsReports: (filters: TransactionsFiltersType) => {
    const transFormedDateRange =
      buildDateRangeFromTextRange("select_range")(filters);

    const filteredData = buildDateRangeFiltersValues(transFormedDateRange);

    return httpClient.get<BlobPart>(transactionsPath.reports, {
      params: filteredData,
      paramsSerializer: commaSerializer,
      responseType: "arraybuffer",
    });
  },
};
