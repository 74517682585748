import { TRANSACTION_TYPES } from "@common/Constants";

import { IDType, QueryParams } from "./commonTypes";
import { ImageFile } from "./imageTypes";
import { PromotionTypesType } from "./promotionTypes";

export type DriversLocationType = {
  parks?: string[];
  tariff?: string[];
  search?: string;
  city_id?: string;
};

export type MapDriver = {
  driver_id: IDType;
  lat: string;
  lon: string;
  park_id: number;
};

export type DriverTypes = {
  online: MapDriver[];
  onride: MapDriver[];
};

export type LandingDriverApplicationType = {
  full_name: string;
  phone: string;
  city_id: number | string;
};

export type DriverItemsType = {
  balance: number;
  car_make: string | null;
  car_color: string | null;
  first_name: string;
  id: number;
  is_active: boolean;
  last_name: string;
  park_name: string;
  phone: string;
  plate_number: string | null;
  second_name: string;
  status: string;
  has_brand: boolean;
  tariffs: DriverTariff[] | null;
  city: {
    id: number;
    name: string;
  };
};

export type initialDriverType = {
  id?: number;
  first_name?: string;
  last_name?: string;
  second_name?: string;
  park_id?: number;
  has_brand?: boolean;
  car_model?: string;
  car_make?: string;
  car_plate_number?: string;
  car_year?: number;
  car_color?: string;
  tariff_ids?: Array<string>;
  issue_country?: string;
  serial_number?: string;
  issue_date?: Date;
  expired_date?: Date;
  car_class?: number;
  iin?: string | null;
};

export type DriverType = {
  length: number;
  items: DriverItemsType[];
};

export type DriversFiltersType = {
  status?: string[];
  parks?: string[];
  tariff?: string[];
  search: string;
  rides_allowed?: string;
  city_id?: string | number;
};

type DriverTariff = {
  id: number;
  name: string;
  name_en: string;
  name_kg: string;
};

export type DriverDetailType = {
  id: number;
  first_name: string;
  last_name: string;
  second_name: string;
  rides_allowed: boolean;
  is_withdrawal_blocked: boolean;
  is_promotions_blocked: boolean;
  phone: string;
  created_date: Date;
  rating: number;
  balance: number;
  bonus: number;
  tariff_ids: Array<string>;
  tariffs: DriverTariff[];
  car: {
    make: string;
    model: string;
    color: string;
    has_brand: boolean;
    plate_number: string;
    year: number;
    car_class: number;
  };
  license: {
    id: number;
    driver_id: IDType;
    full_name: string;
    serial_number: string;
    issue_country: string;
    given_date: Date;
    expiration_date: Date;
    photo_front_id: number;
    photo_back_id: number;
  };
  park: {
    id: number;
    name: string;
    phone: string;
    address: string;
    status: string;
    withdraw_terms: string;
    description: string;
    rating: number;
    cars_amount: number;
    work_start_time: string;
    work_end_time: string;
  };
  block_withdrawal_comment: string | null;
  block_promotions_comment: string | null;
  deactivation_comment: string | null;
  device_model: string | null;
  device_version: string | null;
  app_version: string | null;
  car_photos: string[];
  document_photos: string[];
  city?: {
    id: number;
    name: string;
  };
  iin: string | null;
  car_detail: string;
  car_tariff?: string;
  car_rating: string;
};

// registrations driver types

export interface Car {
  make: string;
  model: string;
  color: string;
  plate_number: string;
  year: string;
}

export interface CarImages {
  front_seat_image_id?: number | null;
  car_trunk_image_id?: number | null;
  back_seat_image_id?: number | null;
  left_side_image_id?: number | null;
  right_side_image_id?: number | null;
  back_side_image_id?: number | null;
  front_side_image_id?: number | null;
}

export interface License {
  issue_country: string;
  serial_number: string;
  given_date: Date | null;
  expiration_date: Date | null;
}

export interface DocumentImages {
  passport_image_id: ImageFile;
  passport_back_image_id: ImageFile;
  license_front_image_id: ImageFile;
  license_back_image_id: ImageFile;
}

export interface CreateDriverType {
  park_id: number | string;
  city_id: number | string;
  phone: string;
  iin: string;
  first_name: string;
  last_name: string;
  second_name: string;
  language: string;
  car: Car;
  car_images: CarImages;
  car_class: number | null;
  tariff_ids: number[] | null;
  license: License;
  document_images: DocumentImages;
  has_brand: boolean;
}

export type DriverOperationValues = {
  amount: number | null;
  type: string;
  description: string;
};

export type DriverOperationType = {
  driverId?: IDType;
  values: DriverOperationValues;
};

export type DriverOperationResponse = {
  driver_id: IDType;
  type: TRANSACTION_TYPES;
  amount: number;
};

export type DriverActivationType = {
  driverId?: IDType;
  values: DriverActivationValues;
};

export type DriverActivationValues = {
  driver_id?: IDType;
  rides_allowed: boolean;
  comment: string;
};

export interface DriverQueryParams extends QueryParams {
  driver_id?: IDType;
}

export interface DriverRidesStatisticsQueryParams {
  city_id?: string;
  date_range?: [string | null, string | null];
}

export interface DriverStatusesCountQueryParams {
  city_id?: string;
  park_id?: string[];
}

export type StatusType =
  | "ride_completed"
  | "driver_not_found"
  | "rider_cancelled"
  | "admin_cancelled"
  | "ride_in_progress"
  | "expecting_rider";

export type DriverHistoryType = {
  id: number;
  driver_first_name: string;
  driver_last_name: string;
  driver_second_name: string;
  driver_phone: string;
  rider_phone: string;
  status: StatusType;
  address: string;
  tariff: string;
  datetime: string;
};

export type DriverHistoryResponse = {
  length: number;
  items: DriverHistoryType[];
};

export type DriverPromotionDetailType = {
  ride_count: number;
  left_ride_count: number;
  is_personal: true;
  is_park_promotion: boolean;
  promotion: {
    id: number | string;
    name: string;
    type: PromotionTypesType;
    amount: number;
    ride_amount: number;
    ended_at: string;
    is_personal: boolean;
  };
  completed_at: string;
};

export type DriverPromotionType = {
  length: number;
  items: DriverPromotionDetailType[];
};

export type DriverTransactionType = {
  id: number;
  amount: number;
  type: string;
  status: string;
  description: string;
  created_at: Date;
};

export type DriverStatusesType = {
  status: string;
  count: number;
};

export type StatisticRides = {
  status: string;
  value: number;
};

export type DriverTransactionResponse = {
  length: number;
  items: DriverTransactionType[];
};

export type RatingType = {
  rider_first_name: string;
  rider_second_name: string;
  rider_last_name: string;
  rider_id: number;
  rating: number;
  comment: string;
  ride_id: number;
};

export type BonusHistoryType = {
  admin_first_name: string;
  admin_second_name: string;
  admin_last_name: string;
  amount: number;
  description: string;
  id: number;
  ride_id: number;
};

export type ActivationType = {
  admin_first_name: string;
  admin_second_name: string;
  admin_last_name: string;
  type: "activation" | "deactivation";
  comment: string;
};

export type TransactionType = {
  admin_first_name: string;
  admin_second_name: string;
  admin_last_name: string;
  type: "withdraw" | "replenishment";
  amount: number;
  description: string;
};

export type CancelledRideHistory = {
  ride_id: number;
  ride_a_address: string;
  admin_user_id: number;
  admin_first_name: string;
  admin_second_name: string;
  admin_last_name: string;
};

export type DriverChangeHistoryType = {
  driver_id: IDType;
  created_at: Date;
  rating: RatingType | null;
  bonus_history: BonusHistoryType | null;
  activation_history: ActivationType | null;
  admin_action: AdminActionChangeHistoryType | null;
  transaction: TransactionType | null;
  cancelled_ride_history: CancelledRideHistory | null;
};

export type AdminActionChangeHistoryType = {
  action: DriverAction | null;
  admin_first_name: string | null;
  admin_last_name: string | null;
  admin_second_name: string | null;
  admin_user_id: number | null;
  comment: string | null;
};

export type DriverChangeHistoryResponse = {
  length: number;
  items: DriverChangeHistoryType[];
};

// statistics
export interface StatisticsQueryParams {
  driver_id: IDType;
  end_date: string;
  start_date: string;
}

export type StatusStatisticsTypes = {
  day: string;
  statuses: [
    {
      status: string;
      start: string;
      duration: number;
    },
  ];
};

export interface StatusStatisticsResponse {
  start_date: string;
  end_date: string;
  one_day: boolean;
  statistics: StatusStatisticsTypes[];
}

export interface StatusStatisticsAverageResponse {
  selected_period: {
    start_date: string;
    end_date: string;
    avg: number;
  };
  previous_period: {
    start_date: string;
    end_date: string;
    avg: number;
  };
}

type TotalStatusStatistics = {
  status: string;
  duration: number;
  percentage: number;
};
export interface StatusStatisticsTotalResponse {
  start_date: string;
  end_date: string;
  statuses: TotalStatusStatistics[];
}

export type StatisticRidesResponse = {
  status: string;
  value: number;
};

export enum DriverAction {
  block_withdraw = "block_withdraw",
  unblock_withdraw = "unblock_withdraw",
  block_promotions = "block_promotions",
  unblock_promotions = "unblock_promotions",
}

export type DriverActionsType = {
  driver_id: string;
  action: DriverAction;
  comment: string;
};

export type DriverActionsResponse = {
  action: DriverAction;
  comment: string;
  driver_id: number;
};
