import { ParkSettingsType } from "./limitsTypes";

export type ParksShort = {
  id: number;
  name: string;
  number: number;
  status: string;
};

export type LandingParkApplicationType = {
  name: string;
  company_name: string;
  phone: string;
  email: string;
  city_id: number | string;
  cars_amount: string;
};

export type ParksDetailType = {
  address: string;
  drivers_amount: number;
  id: number;
  name: string;
  phone: string;
  rating: number;
  tariff_cars: Array<{
    cars_amount: number;
    tariff: string;
  }>;
  configs: ParkSettingsType;
};

export type ParksType = {
  items: ParksDetailType[];
  length: number;
};

export type ParkParams = {
  limit: number;
  skip: number;
  search?: string;
};

export enum PROMOTION_TYPES {
  REFERRAL_PROMOTION = "referral_promocode",
  REGISTRATION_PROMOTION = "registration",
  FIRST_PAYMENT_CARD_SAVE = "first_payment_card_save",
  CASHBACK = "cashback",
  RIDE = "ride",
}
