import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { settingsService } from "@api/services";

type Params = {
  app: string;
};

export const useGetIntros = (filters: Params) => {
  const { data, ...query } = useQuery({
    queryKey: [QUERY_KEYS.INTROS, filters],
    queryFn: async () => await settingsService.getIntros(filters.app),
  });

  const sortedData = data?.sort(
    (a, b) => Number(b.is_selected) - Number(a.is_selected),
  );

  return { data: sortedData, ...query };
};
