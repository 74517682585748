import * as Yup from "yup";

export const balanceSchema = Yup.object().shape({
  amount: Yup.number()
    .required("Укажите сумму транзакции")
    .positive("Не может быть ниже 0")
    .typeError("Только цифры"),
  transaction_type: Yup.string().required("Выберите тип транзакции"),
  description: Yup.string().required("Напишите причину"),
});
