import { parse, stringify } from "query-string";
import { isEmpty, omit, pick, shake } from "radash";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useNavigateTo } from "./use-navigate-to";

/**
 * Custom React hook to manage filters based on URL query parameters.
 *
 * This hook initializes the filters state based on the initial filter values or the query parameters
 * in the URL. It automatically updates the URL when the filters state changes, and it allows for
 * changing the filters via the `onChange` function.
 *
 * @template T
 * @param {T} initialFilters - The initial filter values. These are used if no corresponding query
 * parameters are present in the URL.
 * @param {string[]} trackingFilters - An array of filter keys to track from the query parameters.
 * Only these keys will be synchronized with the URL.
 *
 * @returns {{
 *  filters: T,
 *  setFilters: (values: Partial<T>) => void
 * }} - An object containing the current filters state and a function to update the filters.
 */

export const useFilters = <T>(initialFilters: T, trackingFilters: string[]) => {
  const location = useLocation();
  const { navigateTo } = useNavigateTo();

  const [skip, setSkip] = useState(0);
  const [filters, setFilters] = useState<T>(() => {
    const queryParams = parse(location.search);
    const filtersFromQuery = pick(queryParams, trackingFilters);
    if (!isEmpty(filtersFromQuery)) {
      return filtersFromQuery as unknown as T;
    }
    return initialFilters;
  });

  useEffect(() => {
    const queryParams = parse(location.search);
    const parsedValues = shake(filters, (value) =>
      Array.isArray(value) ? value.every(isEmpty) : isEmpty(value),
    );
    navigateTo(
      `${location.pathname}?${stringify({
        ...omit(queryParams, trackingFilters),
        ...parsedValues,
      })}`,
      {
        replace: true,
      },
    );

    setSkip(0);
  }, [location.search, filters]);

  const onChange = (values: Partial<T>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...values,
    }));
  };

  return {
    filters,
    setFilters: onChange,
    skip,
    setSkip,
  };
};
