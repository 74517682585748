import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { driversService } from "@api/services";
import { DriverRidesStatisticsQueryParams } from "@common/Types";

type Params = DriverRidesStatisticsQueryParams & {
  refetchInterval?: number;
};

export const useGetDriverStatisticsRides = ({
  refetchInterval,
  ...filters
}: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.DRIVER_STATISTICS_RIDES, filters],
    queryFn: async () => await driversService.getDriverStatisticsRides(filters),
    refetchInterval,
  });
};
