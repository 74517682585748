import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { limitsService } from "@api/services";
import { ConfigsType } from "@common/Types";

type Props = {
  cityId: string;
  enabled?: boolean;
};

export const useGetCityConfigs = (params: Props) => {
  return useQuery<ConfigsType>({
    queryKey: [QUERY_KEYS.CONFIGS, params],
    queryFn: async () => await limitsService.getCityConfigs(params.cityId),
    enabled: params.enabled,
  });
};
