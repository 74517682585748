import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { zonesService } from "@api/services";

export const useGetZones = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.ZONES],
    queryFn: async () => await zonesService.getZones(),
  });
};
