import React from "react";

import { ColorClassKey, CommonFieldParams } from "@common/Types";
import { Flexbox, Grid, Typography } from "@components/Elements";

import { FormDescriptionField } from "../form-description-field";

type Props<T> = {
  title?: string;
  params: CommonFieldParams<T>[] | CommonFieldParams<T>;
  isEdit: boolean;
  bgColor?: ColorClassKey;
};

export const Limits = <T,>({
  title,
  params,
  isEdit,
  bgColor = "neutral-50",
}: Props<T>) => {
  return (
    <Flexbox flexDirection="flex-col" className="gap-2">
      {title && (
        <Typography
          color="neutral-800"
          variant="body-2-b"
          className="font-medium"
        >
          {title}
        </Typography>
      )}

      <Grid
        columns="auto-fill"
        minColumnWidth="238px"
        bgColor={bgColor}
        className="w-full py-2 px-4 rounded-lg"
      >
        <FormDescriptionField isEdit={isEdit} params={params} />
      </Grid>
    </Flexbox>
  );
};
