import { appConfig } from "@api/config";
import { EditIntroParams } from "@common/Types";

const src = appConfig.api.dashboard;

export const settingsPath = {
  intro: `${src}/app/intro/`,
  giveBonus: `${src}/drivers/bonus/`,
  getVersions: `${src}/app/versions/`,
  editVersion: (versionId: number) => `${src}/app/${versionId}/`,
  setIntro: ({ introId, os }: EditIntroParams) =>
    `${src}/app/intro/${os}/${introId}/`,
};
