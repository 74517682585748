import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { settingsService } from "@api/services";
import { VersionType } from "@common/Types";

export const useEditVersion = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (version: VersionType) =>
      await settingsService.editVersion(version),

    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.VERSIONS] });

      toast.success(
        `Принудительное обновление для версии ${variables.version} ${
          variables.force_update ? "включено" : "выключено"
        }`,
      );
    },
  });
};
