import * as Yup from "yup";

import {
  booleanNumberValidation,
  getDynamicPercentValidation,
  numberValidation,
} from "@common/Validation";

export const parkParamsSchema = Yup.object().shape({
  use_city_configs: booleanNumberValidation,

  fees: Yup.array().of(
    Yup.object().shape({
      percentage: getDynamicPercentValidation(),
    }),
  ),
});

export const withdrawCommissionParamsSchema = Yup.object().shape({
  daily_withdraw_count_limit: numberValidation,
  withdraw_min_amount: numberValidation,
  daily_withdraw_sum_limit: numberValidation,

  fees: Yup.array().of(
    Yup.object().shape({
      fixed_fee: getDynamicPercentValidation(),
      min_fee: getDynamicPercentValidation(),
      percentage: getDynamicPercentValidation(),
    }),
  ),
});
