import { Switch as MantineSwitch } from "@mantine/core";
import React from "react";

import { VIEW_CLASSES } from "./variants";

type Props = {
  label?: string;
  checked?: boolean | string | number;
  disabled?: boolean;
  className?: string;
  onChange: (value: boolean) => void;
};

export const Switch: React.FC<Props> = ({
  label,
  checked,
  disabled,
  className,
  onChange,
}) => {
  const parseChecked = !!checked;

  return (
    <MantineSwitch
      name="is_default"
      label={label}
      checked={parseChecked}
      disabled={disabled}
      onChange={(e) => onChange(e.currentTarget.checked)}
      classNames={{
        label: VIEW_CLASSES["default"].label,
        input: `${VIEW_CLASSES["default"].input} ${className}`,
      }}
    />
  );
};
