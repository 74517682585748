import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { ridersService } from "@api/services";
import { UpdateRiderStatusParams } from "@common/Types";
import { showErrorToast } from "@common/Utils";

export const useUpdateRiderStatus = () => {
  const q = useQueryClient();
  return useMutation({
    mutationFn: (params: UpdateRiderStatusParams) =>
      ridersService.updateRiderStatus(params),
    onSuccess: (values) => {
      q.invalidateQueries({ queryKey: [QUERY_KEYS.RIDER_DETAIL] });
      toast.success(
        `Профиль пользователя ${
          values.detail === "Аккаунт заблокирован"
            ? "деактивирован"
            : "активирован"
        }`,
      );
    },
    onError: (error) => {
      showErrorToast(error as AxiosError);
    },
  });
};
