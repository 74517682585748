import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { ridersService } from "@api/services";
import { RidersEditHistoryParams } from "@common/Types";

export const useGetRiderEditHistory = (params: RidersEditHistoryParams) => {
  return useQuery({
    queryKey: [QUERY_KEYS.RIDER_EDIT_HISTORY, params],
    queryFn: () => ridersService.getRiderEditHistory(params),
  });
};
