import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { ridersService } from "@api/services";
import { QueryParams } from "@common/Types";

export const useGetRiders = (params: QueryParams) => {
  return useQuery({
    queryKey: [QUERY_KEYS.RIDERS, params],
    queryFn: async () => await ridersService.getRiders(params),
  });
};
