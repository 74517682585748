import clsx from "clsx";
import React from "react";

// local docs
import { backgroundColorClasses } from "@common/Constants";

import { FlexboxBaseProps } from "./types";

/**
 * Flexbox component for creating a flex container.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The content to be displayed inside the flex container.
 * @param {string} [props.className] - Optional additional class names.
 * @param {string} [props.flexDirection='row'] - Flex direction of the container. Can be 'row' or 'column'.
 * @param {string} [props.justifyContent='flex-start'] - Justify content alignment. Defines how items are distributed along the main axis.
 * @param {string} [props.alignItems='flex-start'] - Align items alignment. Defines how items are aligned along the cross axis.
 * @param {ColorTypes} [props.bgColor] - Optional background color configuration for the flex container. Defines the color variant and level.
 * @param {keyof JSX.IntrinsicElements} [props.as] - Optional HTML element type or React component to render. Allows changing the rendered element or component.
 * @returns {JSX.Element} The rendered flex container component.
 */

export const Flexbox: React.FC<FlexboxBaseProps> = ({
  children,
  className,
  flexDirection = "flex-row",
  justifyContent = "justify-start",
  alignItems = "items-start",
  as: Component = "div",
  bgColor,
  ...props
}) => {
  const bgColorClass = bgColor ? backgroundColorClasses[bgColor] : "";

  return (
    <Component
      className={clsx(
        "flex",
        bgColorClass,
        flexDirection,
        justifyContent,
        alignItems,
        className,
      )}
      {...props}
    >
      {children}
    </Component>
  );
};
