import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { driversService } from "@api/services";
import { DriverQueryParams } from "@common/Types";

export const useGetDriverChangeHistory = (params: DriverQueryParams) => {
  return useQuery({
    queryKey: [QUERY_KEYS.DRIVER_CHANGE_HISTORY, params],
    queryFn: async () => await driversService.getDriverChangeHistory(params),
  });
};
