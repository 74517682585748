import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { citiesService } from "@api/services";

type Params = {
  id: string;
};

export const useGetCityDetail = ({ id }: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CITIES_DETAILS, id],
    queryFn: async () => await citiesService.getCity(id),
    enabled: !!id,
  });
};
