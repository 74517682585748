import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { driversService } from "@api/services";
import { CURRENCY_SYMBOL, TRANSACTION_TYPES } from "@common/Constants";
import { DriverOperationType } from "@common/Types";

export const useSetDriverBalance = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: DriverOperationType) =>
      driversService.setDriverBalance(params),

    onSuccess: (_, { values: { type, amount } }) => {
      const transactionType =
        type === TRANSACTION_TYPES.WITHDRAW ? "Списано" : "Начислено";

      toast.success(`${transactionType} ${amount} ${CURRENCY_SYMBOL}`);

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DRIVER_DETAIL] });
    },
  });
};
