import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { driversService } from "@api/services";
import { DriverActivationType } from "@common/Types";

export const useSetDriverActivation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: DriverActivationType) =>
      await driversService.setDriverActivation(params),

    onSuccess: (_, { values: { rides_allowed } }) => {
      toast.success(
        `Профиль водителя ${rides_allowed ? "активирован" : "деактивирован"}`,
      );

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DRIVER_DETAIL] });
    },
  });
};
