import { Lightning } from "phosphor-react";
import React from "react";

import { SurgePricingTariffType } from "@common/Types";
import { Col, Row, Typography } from "@components/Elements";

type Props = {
  prices?: SurgePricingTariffType[];
};

export const DemandCard: React.FC<Props> = ({ prices = [] }) => {
  return (
    <Col className="w-full min-w-[220px] max-w-[400px] mt-4">
      {prices.map((tariff) => (
        <Row
          key={tariff.tariff_id}
          className="w-full"
          alignItems="items-start"
          justifyContent="justify-between"
        >
          <Typography className="w-2/4">{tariff.tariff_name}</Typography>
          <Row alignItems="items-center">
            <Typography className="w-2/4 mr-2">
              {tariff.current_price}
            </Typography>
            {tariff.is_surge_price && (
              <Lightning color="#ADD8E6" weight="fill" size={16} />
            )}
          </Row>
        </Row>
      ))}
    </Col>
  );
};
