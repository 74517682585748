import { ModalView, ModalViewStyles } from "./types";

export const VIEW_CLASSES: Record<ModalView, ModalViewStyles> = {
  dark: {
    modal: "bg-dark-400",
    close: "bg-dark-400",
    title:
      "border-b border-dark-300 text-xl font-bold text-gray-200 py-4 px-4 text-center w-full",
  },
  white: {
    modal: `max-w-full p-[24px] rounded-[8px]`,
    inner: "flex items-center justify-center",
    title: "text-heading-2-b font-semibold leading-[24px]",
    header: "border-b pb-4 m-0",
  },
};
