import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { settingsService } from "@api/services";

export const useGetVersions = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.VERSIONS],
    queryFn: async () => await settingsService.getVersions(),
  });
};
