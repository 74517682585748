import { isEqual } from "radash";
import { MouseEvent, useState } from "react";

type Props<T> = {
  data: T[];
  skip: number;
  limit: number;
};

/**
 * A custom hook that manages row selection for a table with paginated data.
 *
 * @template T - The type of data for each table row.
 * @param {T[]} props.data - The data array for the current page of the table.
 * @param {number} props.skip - The number of rows skipped (used for pagination).
 * @param {number} props.limit - The number of rows per page (used for pagination).
 * @returns {T[]} allSelectedRows - An array of all selected rows across all pages.
 * @returns {() => void} resetAllSelectedRows - A function to reset all selected rows.
 * @returns {(e: MouseEvent<HTMLInputElement>, row: T) => void} handleSelectRow - A function to toggle selection for a specific row.
 * @returns {(e: MouseEvent<HTMLInputElement>) => void} handleSelectAllRows - A function to select or deselect all rows on the current page.
 * @returns {(row: T) => boolean} isRowSelected - A function to check if a specific row is selected.
 * @returns {boolean} isAllRowsSelected - A boolean indicating if all rows on the current page are selected.
 */

export const useTableSelection = <T>({ data, skip, limit }: Props<T>) => {
  const [selectedRows, setSelectedRows] = useState<Record<number, T[]>>({});

  const page = skip / limit + 1;
  const rowsByPage = selectedRows[page] || [];
  const isAllRowsSelected = rowsByPage.length === data.length;

  const isRowSelected = (row: T) => {
    return rowsByPage.some((selectedRow: T) => isEqual(selectedRow, row));
  };

  const handleSelectRow = (e: MouseEvent<HTMLInputElement>, row: T) => {
    e.stopPropagation();

    setSelectedRows((prev) => {
      const updatedRows = isRowSelected(row)
        ? rowsByPage.filter((selectedRow) => !isEqual(selectedRow, row))
        : [...rowsByPage, row];

      return {
        ...prev,
        [page]: updatedRows,
      };
    });
  };

  const handleSelectAllRows = (e: MouseEvent<HTMLInputElement>) => {
    const checked = e.currentTarget.checked;

    setSelectedRows((prev) => ({
      ...prev,
      [page]: checked ? [...data] : [],
    }));
  };

  const allSelectedRows = Object.values(selectedRows).flat();

  const resetAllSelectedRows = () => {
    setSelectedRows([]);
  };

  return {
    allSelectedRows,
    resetAllSelectedRows,
    handleSelectRow,
    handleSelectAllRows,
    isRowSelected,
    isAllRowsSelected,
  };
};
