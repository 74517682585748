/**
 * Сервисы для limits
 */

import { appConfig } from "@api/config";

const src = appConfig.api.dashboard;

export const limitsPath = {
  city_configs: `${src}/configs/`,
  park_configs: (parkId: string) => `${src}/parks/${parkId}/configs/`,
  city_fees: (cityId: string) => `${src}/fees/cities/${cityId}/`,
  park_fees: (parkId: string) => `${src}/fees/parks/${parkId}/`,
  update_fee: (feeId: number) => `${src}/fee_configs/${feeId}/`,
};
