import React from "react";

import { CURRENCY_SYMBOL } from "@common/Constants";
import { FeesCommissionType, FeesConfigType } from "@common/Types";
import { FormExtendedSwitchField, Limits } from "@components/Shared";

import { WithdrawFormSwitchFields } from "../../../common/constants/consts";

type Props = {
  configs: FeesConfigType[];
  isEdit: boolean;
  type: FeesCommissionType;
};

export const WithdrawLimits = ({ configs, isEdit, type }: Props) => {
  const transformedConfigs = configs.filter((config) => config.type === type);

  const getIndexById = (id: number) => {
    return configs.findIndex((config) => config.id === id);
  };

  if (type === "ride_payment" && transformedConfigs.length) {
    return (
      <Limits
        title="Комиссия за поездку"
        isEdit={isEdit}
        params={transformedConfigs.map((config) => ({
          key: config.id,
          name: config.partner_name,
          field: `fees[${getIndexById(config.id)}].percentage`,
        }))}
      />
    );
  }

  return (
    <>
      {transformedConfigs.map((config) => {
        const { id, partner_id, partner_name } = config;

        return (
          <FormExtendedSwitchField
            key={id}
            name={`Вывод на ${partner_name}`}
            field={WithdrawFormSwitchFields[partner_id] ?? ""}
            isEdit={isEdit}
            params={[
              {
                name: "Текст",
                field: `fees[${getIndexById(config.id)}].operation_title`,
              },
              {
                name: `Фиксированная комиссия (${CURRENCY_SYMBOL})`,
                field: `fees[${getIndexById(config.id)}].fixed_fee`,
              },
              {
                name: "Комиссия в %",
                field: `fees[${getIndexById(config.id)}].percentage`,
              },
              {
                name: `Минимальная комиссия (${CURRENCY_SYMBOL})`,
                field: `fees[${getIndexById(config.id)}].min_fee`,
              },
            ]}
          />
        );
      })}
    </>
  );
};
