import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { settingsService } from "@api/services";
import { DriverBonuses } from "@common/Types";

export const useGiveBonus = () => {
  return useMutation({
    mutationFn: async (bonus: DriverBonuses) =>
      await settingsService.giveBonus(bonus),

    onSuccess: (_, variables) =>
      toast.success(`Начислено ${variables.bonus} бонусов`),
  });
};
