import { httpClient } from "@common/Libs/axios";
import {
  CreateIntroType,
  DriverBonuses,
  EditIntroParams,
  IntroType,
  VersionType,
} from "@common/Types";

import { settingsPath } from "./path";

export const settingsService = {
  giveBonus: (bonus: DriverBonuses) =>
    httpClient.post(settingsPath.giveBonus, bonus),

  getVersions: () => httpClient.get<VersionType[]>(settingsPath.getVersions),

  editVersion: (version: VersionType) => {
    const { id } = version;

    return httpClient.patch(settingsPath.editVersion(id), version);
  },

  getIntros: (app: string) =>
    httpClient.get<IntroType[]>(settingsPath.intro, { params: { app } }),

  uploadIntro: (intro: CreateIntroType) =>
    httpClient.post(settingsPath.intro, intro),

  editIntro: (params: EditIntroParams) =>
    httpClient.put(settingsPath.setIntro(params)),

  deleteIntro: (params: EditIntroParams) =>
    httpClient.delete(settingsPath.setIntro(params)),
};
