import { Point } from "geojson";

import { InitialViewportType } from "@common/Types";

export const buildZoneInitialValue = (
  initialViewport: InitialViewportType,
  point?: Point | null,
  zoom?: number,
) => {
  if (point) {
    return {
      ...initialViewport,
      zoom: zoom || initialViewport.zoom,
      longitude: point.coordinates[0],
      latitude: point.coordinates[1],
    };
  }

  return initialViewport;
};
