import React from "react";

import { Button, Flexbox, Modal } from "@components/Elements";

type Props = {
  title: string;
  confirmBtnLabel?: string;
  opened: boolean;
  loading: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmModal = ({
  title,
  opened,
  confirmBtnLabel = "Сохранить",
  loading,
  onConfirm,
  onClose,
}: Props) => {
  return (
    <Modal title={title} opened={opened} onClose={onClose}>
      <Flexbox className="gap-2 pt-6">
        <Button
          size="lg"
          className="w-[160px]"
          onClick={onConfirm}
          loading={loading}
        >
          {confirmBtnLabel}
        </Button>

        <Button
          view="transparent"
          size="lg"
          className="w-[160px]"
          onClick={onClose}
          loading={loading}
        >
          Отмена
        </Button>
      </Flexbox>
    </Modal>
  );
};
