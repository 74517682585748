import { useField } from "formik";
import React from "react";

import { CommonFieldParams } from "@common/Types";

import { FormSwitchField } from "../form-switch-field";
import { Limits } from "../limits";

type Props<T> = {
  name: string;
  field: keyof T;
  isEdit: boolean;
  params: CommonFieldParams<T>[] | CommonFieldParams<T>;
};

export const FormExtendedSwitchField = <T,>({
  name,
  field,
  isEdit,
  params,
}: Props<T>) => {
  const [fieldProps] = useField(String(field));

  const currentValue = fieldProps.value ?? 0;

  return (
    <div className="bg-neutral-bg-50 rounded-lg">
      <FormSwitchField
        bgColor="transparent"
        isEdit={isEdit}
        params={[
          {
            name: name,
            field: field,
          },
        ]}
      />

      {!!currentValue && <Limits isEdit={isEdit} params={params} />}
    </div>
  );
};
