import { httpClient } from "@common/Libs/axios";
import { CoordinatesType, H3Type, SurgePricingTariffType } from "@common/Types";

import { zonesPath } from "./paths";

/**
 * Описание методов zones.
 */

export const zonesService = {
  getZones: () => {
    return httpClient.get<H3Type>(zonesPath.zones);
  },
  getZonePrice: (coordinate: CoordinatesType) => {
    return httpClient.post<SurgePricingTariffType[], CoordinatesType>(
      zonesPath.zones_prices,
      coordinate,
    );
  },
};
