import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { settingsService } from "@api/services";
import { EditIntroParams } from "@common/Types";

export const useEditIntro = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: EditIntroParams) =>
      await settingsService.editIntro(params),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.INTROS] });

      toast.success("Интро успешно обновлено");
    },
  });
};
