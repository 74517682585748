import { Buildings } from "phosphor-react";
import React from "react";

import { AVAILABLE_LANGUAGE_FILTER } from "@common/Libs/i18next";

import { CheckboxList } from "../checkbox-list";

type Props<Filters> = {
  filters: Filters;
  setFilters: (value: Filters) => void;
};

export const LanguageFilter = <T,>({ filters, setFilters }: Props<T>) => {
  return (
    <CheckboxList
      MainIcon={<Buildings />}
      title={"Язык"}
      filters={filters}
      setFilters={setFilters}
      field={"language" as keyof T}
      options={AVAILABLE_LANGUAGE_FILTER}
      singleChoice
    />
  );
};
