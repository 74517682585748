import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import { Spinner } from "phosphor-react";
import React from "react";

import { BALANCE, CURRENCY_SYMBOL } from "@common/Constants";
import { CreateOperationFormType } from "@common/Types";
import { Button, Modal, Typography } from "@components/Elements";
import { FormRow, FormSelect } from "@components/Shared";

import { balanceSchema } from "../../common/validation";

const DEFAULT_TRANSACTION_AMOUNTS = ["50", "100", "200", "500", "1000"];

const FastAmountCards = ({
  onClick,
  type,
}: {
  onClick: (amount: number) => void;
  type: "balance" | "bonus";
}) => {
  return (
    <div className={"w-full flex gap-[6px]"}>
      {DEFAULT_TRANSACTION_AMOUNTS.map((option) => (
        <div
          className={
            "flex w-[calc(20%-5px)] rounded-[8px] border border-neutral-border-300"
          }
          key={option}
        >
          <button
            type={"button"}
            className={
              "w-full flex flex-col items-center justify-between text-center px-[12px] py-[6px]"
            }
            onClick={() => onClick(Number(option))}
          >
            {type === "bonus" ? (
              <>
                <Typography as={"p"} color={"neutral-900"} variant={"body-1-a"}>
                  {option}
                </Typography>
                <Typography
                  as={"p"}
                  color={"neutral-900"}
                  variant={"caption-a"}
                >
                  бонусов
                </Typography>
              </>
            ) : (
              <Typography
                as={"span"}
                variant={"body-2-a"}
                color={"neutral-900"}
              >
                {option} {CURRENCY_SYMBOL}
              </Typography>
            )}
          </button>
        </div>
      ))}
    </div>
  );
};

type Props = {
  show: boolean;
  close: () => void;
  onSubmit: (values: CreateOperationFormType) => Promise<void>;
  title: string | undefined;
  type: "balance" | "bonus";
};

export const CreateOperationForm = ({
  show,
  close,
  onSubmit,
  title,
  type,
}: Props) => {
  const submitForm = async (
    values: CreateOperationFormType,
    {
      setSubmitting,
      close,
    }: { setSubmitting: (isSubmitting: boolean) => void; close: () => void },
  ) => {
    await onSubmit(values);
    setSubmitting(false);
    close();
  };

  return (
    <Modal
      opened={show}
      onClose={close}
      className={"rounded-[8px]"}
      title={title}
    >
      <Formik
        initialValues={{} as CreateOperationFormType}
        validationSchema={balanceSchema}
        onSubmit={(values, { setSubmitting }) =>
          submitForm(values, { setSubmitting, close })
        }
      >
        {({ errors, touched, setFieldValue, values, isSubmitting }) => (
          <Form>
            <div className="flex items-start flex-col py-5 space-y-4">
              <FormSelect
                field={"transaction_type"}
                label={"Тип транзакции"}
                placeholder={"Выберите тип транзакции"}
                data={BALANCE}
              />
              <FormRow
                name={"amount"}
                label={"Сумма"}
                placeholder={"Сумма"}
                touched={touched.amount}
                error={errors.amount}
              />
              <FastAmountCards
                onClick={(amount: number) => setFieldValue("amount", amount)}
                type={type === "bonus" ? "bonus" : "balance"}
              />
              <div className="flex flex-col items-start w-full space-y-1">
                <label className="text-caption-b text-neutral-text-800 font-medium">
                  Причина
                </label>
                <Field
                  as="textarea"
                  rows="2"
                  name="description"
                  placeholder={"Напишите причину"}
                  className="resize-none px-4 py-2 w-full placeholder:text-neutral-text-400 border border-neutral-border-300 rounded-[4px] focus:outline-none focus:border-gray-400 transition-colors appearance-none cursor-pointer placeholder:text-body-1-a"
                />
                {errors.description && touched.description && (
                  <div className="flex justify-center text-caption-b text-red-500">
                    {errors.description}
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center justify-center">
              <Button
                type="submit"
                disabled={!values}
                className={`flex w-full items-center justify-center ${
                  clsx(
                    values.transaction_type &&
                      values.amount &&
                      values.description,
                  )
                    ? "bg-brand-bg-200 text-brand-bg-1000"
                    : "bg-neutral-bg-100 text-neutral-text-300 font-semibold text-body-1-a"
                } space-x-2 py-[12px]  text-sm font-medium rounded-[12px] focus:outline-none focus:ring-1 focus:ring-offset-1`}
              >
                {isSubmitting && (
                  <Spinner className="animate-spin" color={"#fff"} size={16} />
                )}
                Подтвердить операцию
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
