import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { TransactionsService } from "@api/services";
import { TransactionsFiltersType } from "@common/Types";

type Params = {
  filters: TransactionsFiltersType;
  enabled: boolean;
};

export const useGetDriversTransactionsReports = ({
  filters,
  enabled,
}: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.TRANSACTIONS_DRIVERS_REPORTS, filters],
    queryFn: async () =>
      await TransactionsService.getDriverTransactionsReports(filters),
    enabled,
  });
};
