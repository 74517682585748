import { Checkbox } from "@mantine/core";
import { useField } from "formik";
import { isArray } from "radash";
import React from "react";

import { CommonFieldParams } from "@common/Types";
import { Flexbox, Typography } from "@components/Elements";

type Props<T> = {
  params: CommonFieldParams<T>[] | CommonFieldParams<T>;
  isEdit: boolean;
};

export const FormCheckboxField = <T,>({ params, isEdit }: Props<T>) => {
  const transformedParams = isArray(params) ? params : [params];

  return (
    <>
      {transformedParams.map((limit: CommonFieldParams<T>, index: number) => {
        const { name, field } = limit;
        const [fieldProps, _, helper] = useField(String(field));

        const currentValue = fieldProps.value;
        const { setValue } = helper;

        return (
          <Flexbox
            justifyContent="justify-between"
            alignItems="items-center"
            key={`${String(field)}-${index}`}
          >
            <Typography color="neutral-800">{name}</Typography>

            <Checkbox
              size="md"
              checked={currentValue}
              disabled={!isEdit}
              classNames={{ icon: "opacity-0" }}
              onChange={(e) => setValue(e.currentTarget.checked)}
            />
          </Flexbox>
        );
      })}
    </>
  );
};
