import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { ridersService } from "@api/services";

export const useGetRiderDetail = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.RIDER_DETAIL, id],
    queryFn: async () => await ridersService.getRiderDetail(id),
  });
};
