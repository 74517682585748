import { useGetPermissions } from "@api/queries";
import { isTargetNavi } from "@assets/Flags";
import {
  PERMISSIONS_MOTOR,
  PERMISSIONS_NAVI,
  ROLES_MOTOR,
  ROLES_NAVI,
} from "@common/Constants";

import { sidebarRoutes } from "../constants";

type Role = ROLES_NAVI | ROLES_MOTOR;

/**
 * Custom hook to get filtered routes based on the provided role.
 *
 * @param {Role} role - The role of the user, which determines which permissions and routes are used.
 * @returns {Array} - The filtered routes based on the active permissions for the given role.
 */

export const useGetSidebarRoutes = (role: Role) => {
  const getFilteredRoutes = (
    activePageNames: string[],
    key: "renderKey" | "path",
  ) => {
    return sidebarRoutes.map((route) => ({
      ...route,
      routes: route.routes.filter(
        (route) => activePageNames?.includes(route[key]),
      ),
    }));
  };

  if (role === ROLES_NAVI.NAVIADMIN || role === ROLES_MOTOR.MOTORADMIN) {
    const { response = [] } = useGetPermissions({});

    const activePageNames = response
      .filter((permission) => permission.is_active)
      .map((permission) => permission.name);

    return getFilteredRoutes(activePageNames, "renderKey");
  }

  const activePageNames = isTargetNavi
    ? PERMISSIONS_NAVI[role]
    : PERMISSIONS_MOTOR[role];

  return getFilteredRoutes(activePageNames, "path");
};
