import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { limitsService } from "@api/services";
import { UpdateCityConfigQueryParams } from "@common/Types";

type Params = UpdateCityConfigQueryParams;

export const useUpdateCityConfigs = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: Params) =>
      await limitsService.updateCityConfigs(params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CONFIGS],
      });
    },
    retry: 1,
  });
};
