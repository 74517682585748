export const validateImage = (
  image: File,
  dimensions: number[][] = [],
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(image);

    const dimensionStrings = dimensions
      .map(([w, h]) => `${w}x${h}`)
      .join(" / ");

    img.onload = () => {
      const isValidSize = dimensions?.some(
        ([w, h]) =>
          (img.width === w && img.height === h) ||
          (img.width === h && img.height === w),
      );

      if (isValidSize || !dimensions?.length) {
        resolve();
      } else {
        reject(
          new Error(`Неподходящий размер, требуется:
            ${dimensionStrings}`),
        );
      }
    };
    img.onerror = () => reject(new Error("Ошибка загрузки изображения"));
  });
};
