import React from "react";

import { PencilBorderedIcon } from "@assets/Icons";
import { Button, Flexbox } from "@components/Elements";

type Props = {
  isEdit: boolean;
  onClose: () => void;
  onOpen: () => void;
};

export const ButtonActionLimits = ({ isEdit, onClose, onOpen }: Props) => {
  return isEdit ? (
    <Flexbox flexDirection="flex-col" className="gap-[10px] sm:flex-row">
      <Button
        type="reset"
        view="transparent"
        onClick={onClose}
        className="py-2 rounded-[4px]"
      >
        Отменить
      </Button>

      <Button type="submit" className="py-2 rounded-[4px]">
        Сохранить
      </Button>
    </Flexbox>
  ) : (
    <Button type="reset" view="none" onClick={onOpen}>
      <PencilBorderedIcon />
    </Button>
  );
};
