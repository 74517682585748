import { Textarea as MantineTextArea } from "@mantine/core";
import React from "react";

import { TextAreaView } from "./types";
import { VIEW_CLASSES } from "./variants";

/**
 * A customizable text area component built on top of Mantine's `Textarea`,
 * with additional Tailwind CSS styling and support for dynamic class merging.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.name - The name of the text area input.
 * @param {string} props.label - The label for the text area input.
 * @param {string} props.placeholder - The placeholder text displayed in the text area.
 * @param {string} [props.description] - An optional description text displayed below the text area.
 * @param {string | null} [props.error] - An optional error message displayed below the text area.
 * @param {string | number} [props.value] - The current value of the text area.
 * @param {boolean} [props.required=false] - Whether the text area is required.
 * @param {string} [props.className] - Additional custom class names to apply to the text area.
 * @param {number} [props.minRows=3] - Minimum number of rows the text area should display.
 * @param {number} [props.maxRows=6] - Maximum number of rows the text area should display.
 *
 * @returns {React.FC} A React functional component that renders a styled text area input.
 */

type Props = {
  name: string;
  label: string;
  view: TextAreaView;
  placeholder: string;
  description?: string;
  error?: string | null;
  value?: string | number;
  required?: boolean;
  className?: string;
  minRows?: number;
  maxRows?: number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export const TextArea: React.FC<Props> = ({
  name,
  label,
  view = "default",
  placeholder,
  description,
  value,
  required = false,
  error,
  className,
  onChange,
  minRows = 3,
  maxRows = 6,
  ...props
}) => {
  return (
    <MantineTextArea
      name={name}
      value={value}
      placeholder={placeholder}
      label={label}
      minRows={minRows}
      maxRows={maxRows}
      required={required}
      error={error}
      description={description}
      className={className}
      onChange={onChange}
      classNames={{
        ...VIEW_CLASSES[view],
      }}
      {...props}
    />
  );
};
