import { isDate, parse } from "date-fns";

export const isDateStringRange = (
  dates: unknown,
  format: string,
): dates is [string, string] => {
  return (
    Array.isArray(dates) &&
    dates.length === 2 &&
    typeof dates[0] === "string" &&
    typeof dates[1] === "string" &&
    isDate(parse(dates[0], format, new Date())) &&
    isDate(parse(dates[1], format, new Date()))
  );
};
