import { Form, Formik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";

import { ConfigsType, initialParams } from "@common/Types";
import { Block, Limits } from "@components/Shared";

import { ButtonActionLimits } from "../../components/shared/button-action-limits";
import { WithdrawLimits } from "../../components/shared/withdraw-limits";
import { withdrawCommissionParamsSchema } from "../validation";

type Props = {
  initialParams: initialParams;
  updateParams: (values: initialParams) => void;
};

export const CityWithdrawCommissionParams = ({
  initialParams,
  updateParams,
}: Props) => {
  const [isEdit, setIsEdit] = useState(false);

  const onSubmitForm = async (values: initialParams) => {
    const loading_toast = toast.loading("Загрузка");

    try {
      await updateParams(values);
      setIsEdit(false);

      toast.success(`Лимиты успешно изменены`, {
        id: loading_toast,
      });
    } catch (error) {
      toast.remove(loading_toast);
    }
  };

  const onCloseForm = (callback: () => void) => {
    setIsEdit(false);
    callback();
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={withdrawCommissionParamsSchema}
      initialValues={initialParams}
      onSubmit={onSubmitForm}
    >
      {({ resetForm }) => {
        return (
          <Form>
            <Block
              title="Настройки вывода средств и комиссий"
              renderRightItem={
                <ButtonActionLimits
                  isEdit={isEdit}
                  onClose={() => onCloseForm(resetForm)}
                  onOpen={() => setIsEdit(true)}
                />
              }
            >
              <div className="space-y-6 pt-4">
                <Limits<ConfigsType>
                  title="Лимиты по умолчанию"
                  isEdit={isEdit}
                  params={[
                    {
                      name: "Количество операций вывода",
                      field: "daily_withdraw_count_limit",
                    },
                    {
                      name: "Минимальная сумма вывода",
                      field: "withdraw_min_amount",
                    },
                    {
                      name: "Максимальная сумма вывода",
                      field: "daily_withdraw_sum_limit",
                    },
                  ]}
                />

                <WithdrawLimits
                  type="driver_withdraw"
                  configs={initialParams.fees}
                  isEdit={isEdit}
                />
              </div>
            </Block>
          </Form>
        );
      }}
    </Formik>
  );
};
