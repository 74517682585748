import React from "react";

import { useGetParksShort } from "@api/queries";
import { FilterTabType, ParksShort } from "@common/Types";

import { CheckboxList } from "../checkbox-list";

import { TaxiParkIcon } from "./assets/icons";

type Props<T> = {
  filters: T;
  setFilters: (filters: T) => void;
  filterName: string;
};

const buildParksFilter = (parks: ParksShort[]): FilterTabType[] => {
  return parks.map((park) => ({
    label: park.name,
    value: park.id.toString(),
  }));
};

export const ParksFilter = <T extends { city_id?: string | number }>({
  filters,
  setFilters,
  filterName,
}: Props<T>) => {
  const selectedCity = filters.city_id;

  const { data: parksShorts = [] } = useGetParksShort(selectedCity);

  const parks = buildParksFilter(parksShorts);

  return (
    <CheckboxList
      MainIcon={<TaxiParkIcon />}
      filters={filters}
      setFilters={setFilters}
      title={"Таксопарк"}
      field={filterName as keyof T}
      options={parks}
    />
  );
};
