export const driverStatuses = (status: string): string => {
  switch (status) {
    case "online":
      return "На линии";
    case "offline":
      return "Оффлайн";
    case "on_ride":
      return "В пути";
    case "busy":
      return "Занят";
    case "total":
      return "Всего";
    case "new":
      return "Новая";
    case "searching_for_driver":
      return "Поиск водителя";
    case "driver_found":
      return "Водитель найден";
    case "offered_ride_to_driver":
      return "Предложен водителю";
    case "driver_not_found":
      return "Водитель не найден";
    case "driver_coming":
      return "Водитель подъезжает";
    case "expecting_rider":
      return "Ожидается пассажир";
    case "rider_cancelled":
      return "Отменен пассажиром";
    case "ride_in_progress":
      return "Поездка в процессе";
    case "ride_on_hold":
      return "Поездка на ожидании";
    case "ride_completed":
      return "Поездка завершена";
    case "replenishment":
      return "Пополнение";
    case "withdraw":
      return "Списание";
    case "driver_declined":
      return "Водитель проигнорировал";
    case "driver_missed":
      return "Водитель пропустил";
    case "in_progress":
      return "Использование функции";
    case "finished":
      return "Завершение функции";
    case "not_found":
      return "Поездка не найдена";
    case "admin_cancelled":
      return "Отменен администратором";
    case "rider_cancelled_on_ride":
      return "Отменен пассажиром во время поездки";
    default:
      return status;
  }
};
