import React from "react";

export const MoreIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99336 8.75C4.30338 8.75 3.74339 9.31 3.75006 10C3.75006 10.69 4.31005 11.25 5.00003 11.25C5.69001 11.25 6.25 10.69 6.25 10C6.24667 9.31 5.68668 8.75 4.99336 8.75Z"
        fill="#333333"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99336 8.75C9.30338 8.75 8.74339 9.31 8.75006 10C8.75006 10.69 9.31005 11.25 10 11.25C10.69 11.25 11.25 10.69 11.25 10C11.2467 9.31 10.6867 8.75 9.99336 8.75Z"
        fill="#333333"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9934 8.75C14.3034 8.75 13.7434 9.31 13.7501 10C13.7501 10.69 14.31 11.25 15 11.25C15.69 11.25 16.25 10.69 16.25 10C16.2467 9.31 15.6867 8.75 14.9934 8.75Z"
        fill="#333333"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
