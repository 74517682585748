import { useMutation } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { zonesService } from "@api/services";
import { CoordinatesType } from "@common/Types";

export const useGetZonePrice = () => {
  return useMutation({
    mutationKey: [QUERY_KEYS.ZONES_PRISES],
    mutationFn: async (coordinates: CoordinatesType) =>
      await zonesService.getZonePrice(coordinates),
  });
};
