import geojson2h3 from "geojson2h3";
import React from "react";
import { Layer, Source } from "react-map-gl";

import { useGetZones } from "@api/queries";

export const MapLayer = () => {
  const { data: zones } = useGetZones();

  const layers = React.useMemo(() => {
    if (!zones) return null;
    return Object.fromEntries(
      Object.entries(zones).map(([key, value]) => [
        key,
        geojson2h3.h3SetToFeatureCollection(value),
      ]),
    );
  }, [zones]);

  if (!layers) return null;

  return (
    <>
      {Object.entries(layers).map(([key, value], index) => {
        const fillOpacity = Number(`0.${index}9`);

        return (
          <Source key={key} id={key} type="geojson" data={value}>
            <Layer
              type="fill"
              id={key}
              paint={{
                "fill-color": "#0085FF",
                "fill-opacity": fillOpacity,
              }}
              source={key}
            />
            <Layer
              type="line"
              id={key}
              paint={{
                "line-color": "#ffffff",
                "line-width": 1,
              }}
              source={key}
            />
          </Source>
        );
      })}
    </>
  );
};
