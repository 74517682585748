import { DriverAction } from "@common/Types";

export const getAdminActionNameInDriver = (action: DriverAction) => {
  switch (action) {
    case DriverAction.block_withdraw:
      return "Вывод средств заблокирован";
    case DriverAction.unblock_withdraw:
      return "Вывод средств разблокирован";
    case DriverAction.block_promotions:
      return "Доступ к акциям заблокирован";
    case DriverAction.unblock_promotions:
      return "Доступ к акциям разблокирован";
    default:
      return action;
  }
};
