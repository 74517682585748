import React, { useState } from "react";
import * as Yup from "yup";

import { Button, Modal, TextArea, Typography } from "@components/Elements";

import { confirmModalWithReasonValidationSchema } from "./validation";

type Values = {
  comment?: string;
};

type Props = {
  buttonText: string;
  contentText: string;
  isReasonRequired?: boolean;
  onChangeComment: (values: Values) => void;
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  title: string;
  values: Values;
};

export const ConfirmModalWithReason: React.FC<Props> = ({
  open,
  onClose,
  title,
  buttonText,
  contentText,
  onSubmit,
  values,
  onChangeComment,
  isReasonRequired = false,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<Values>();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setErrors((prevState) => ({
      ...prevState,
      [name]: null,
    }));

    onChangeComment({ [name]: value });
  };

  const validateForm = async () => {
    const schema =
      await confirmModalWithReasonValidationSchema(isReasonRequired);

    try {
      schema.validateSync({ comment: values.comment }, { abortEarly: false });
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors: Values = error.inner.reduce(
          (acc: Values, validationError: Yup.ValidationError) => {
            const fieldName = validationError.path as keyof Values;
            acc[fieldName] = validationError.message;
            return acc;
          },
          {},
        );

        setErrors(validationErrors);
      } else {
        console.error("Validation failed with an unexpected error:", error);
      }

      return false;
    }
  };

  const handleButtonClick = async () => {
    setIsSubmitting(true);

    const isValid = await validateForm();

    if (isValid) {
      onSubmit();
    }

    setIsSubmitting(false);
  };

  const handleResetAllData = () => {
    onChangeComment({ comment: "" });
    setErrors({});
  };

  const handleClose = () => {
    handleResetAllData();
    setIsSubmitting(false);
    onClose();
  };

  return (
    <Modal title={title} opened={open} onClose={handleClose}>
      <div className="pt-6">
        <div className="space-y-6">
          <div className="space-y-4">
            <Typography variant="body-2-a" color="neutral-800" as="p">
              {contentText}
            </Typography>

            <TextArea
              name="comment"
              label="Причина"
              placeholder="Напишите причину"
              value={values?.comment}
              onChange={handleChange}
              required
              minRows={2}
              error={errors?.comment}
              view={"default"}
            />
          </div>

          <Button
            type="button"
            size="lg"
            fullWidth
            loading={isSubmitting}
            onClick={handleButtonClick}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
