const classes = {
  label: "text-body-1-a select-none",
  input:
    "min-w-[40px] w-[40px] h-[20px] bg-none enabled:checked:bg-brand-bg-500 border-inherit before:w-[16px] before:h-[16px] before:translate-x-[1px] before:checked:translate-x-[21px] focus:ring-0 focus:ring-offset-0 cursor-pointer",
};

export const VIEW_CLASSES = {
  default: {
    label: classes.label,
    input: classes.input,
  },
};
