import { httpClient } from "@common/Libs/axios";
import {
  ConfigsType,
  FeesConfigType,
  FeesType,
  UpdateCityConfigQueryParams,
  UpdateParkConfigQueryParams,
} from "@common/Types";

import { limitsPath } from "./path";

export const limitsService = {
  getCityConfigs: (cityId: string) =>
    httpClient.get<ConfigsType>(limitsPath.city_configs, {
      params: {
        city_id: cityId,
      },
    }),

  updateCityConfigs: ({ cityId, configs }: UpdateCityConfigQueryParams) =>
    httpClient.patch(limitsPath.city_configs, configs, {
      params: {
        city_id: cityId,
      },
    }),

  updateParkConfigs: ({ parkId, configs }: UpdateParkConfigQueryParams) =>
    httpClient.put(limitsPath.park_configs(parkId), configs),

  getCityFees: (cityId: string) =>
    httpClient.get<FeesType>(limitsPath.city_fees(cityId)),

  getParkFees: (cityId: string) =>
    httpClient.get<FeesType>(limitsPath.park_fees(cityId)),

  updateFee: (fee: FeesConfigType) => {
    const { operation_title, fixed_fee, min_fee, percentage } = fee;

    return httpClient.patch(limitsPath.update_fee(fee.id), {
      operation_title,
      fixed_fee,
      min_fee,
      percentage,
    });
  },
};
