import { httpClient } from "@common/Libs/axios";
import { AdminCityInfo, CitiesType, CityDetailType } from "@common/Types";

import { citiesPath } from "./paths";

export const citiesService = {
  getCities: (skip: number, limit: number) =>
    httpClient.get<CitiesType>(citiesPath.cities, {
      params: { skip, limit },
    }),

  getCity: (id: string) => httpClient.get<CityDetailType>(citiesPath.city(id)),
  getAdminCities: () => httpClient.get<AdminCityInfo>(citiesPath.admin_city),
};
