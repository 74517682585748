import { Popover as PopoverMantine, PopoverProps } from "@mantine/core";
import React from "react";

type Props = PopoverProps;

export const Popover = ({ children, ...rest }: Props) => {
  return (
    <PopoverMantine
      position="bottom"
      placement="end"
      shadow="md"
      zIndex={20}
      classNames={{
        inner: "p-1",
      }}
      {...rest}
    >
      {children}
    </PopoverMantine>
  );
};
