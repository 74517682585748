import { Field, useField } from "formik";
import { isArray } from "radash";
import React from "react";

import { CommonFieldParams } from "@common/Types";
import { Col, TextInput, Typography } from "@components/Elements";

type Props<T> = {
  params: CommonFieldParams<T>[] | CommonFieldParams<T>;
  isEdit?: boolean;
};

export const FormDescriptionField = <T,>({
  params,
  isEdit = false,
}: Props<T>) => {
  const transformedParams = isArray(params) ? params : [params];

  return (
    <>
      {transformedParams.map((param: CommonFieldParams<T>, index: number) => {
        const { name, field, symbol = "", disabled = false } = param;
        const [fieldProps, meta] = useField(String(field));

        const currentValue = fieldProps.value;

        return (
          <Col key={`${String(name)}-${index}`} className="gap-1 flex-wrap">
            {!isEdit ? (
              <>
                <Typography
                  color="neutral-500"
                  variant="body-2-b"
                  as="dt"
                  className="font-medium"
                >
                  {name}
                </Typography>

                <Typography color="neutral-800" as="dd">
                  {currentValue != null ? `${currentValue}${symbol}` : "-"}
                </Typography>
              </>
            ) : (
              <Field
                name={field}
                label={name}
                placeholder="Введите значение"
                disabled={disabled}
                error={(meta.touched && meta.error) || null}
                as={TextInput}
              />
            )}
          </Col>
        );
      })}
    </>
  );
};
