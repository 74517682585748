import { Select } from "@mantine/core";
import { Field, useField } from "formik";
import React from "react";

import { Props } from "./types";
import { VIEW_CLASSES } from "./variants";

export const FormSelect: React.FC<Props> = ({
  view = "default",
  field,
  disabled,
  ...props
}) => {
  const [fieldProps, { touched, error }, { setValue, setTouched }] =
    useField(field);

  const currentValue = String(fieldProps.value);
  const hasError = touched && error;

  let currentView = view;

  if (disabled) {
    currentView = view === "no-border" ? "no-border-disabled" : "disabled";
  } else if (hasError) {
    currentView = "error";
  }

  return (
    <div className="flex flex-col items-start justify-start space-y-1 w-full relative">
      <Field
        {...fieldProps}
        {...props}
        value={currentValue}
        onChange={setValue}
        onBlur={() => setTouched(true)}
        classNames={VIEW_CLASSES[currentView]}
        error={hasError}
        as={Select}
      />
    </div>
  );
};
