import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { driversService } from "@api/services";
import { IDType } from "@common/Types";

type Params = {
  driverId?: IDType;
  enabled?: boolean;
};

export const useGetDriverDetail = ({ driverId, enabled }: Params) => {
  const { data, ...query } = useQuery({
    queryKey: [QUERY_KEYS.DRIVER_DETAIL, driverId],
    queryFn: async () => await driversService.getDriverDetail(driverId),
    enabled,
  });

  const carRating = `${data?.rating}.0`;
  const carTariff = data?.tariffs?.map((tariff) => tariff.name).join(", ");
  const carDetail = [data?.car?.color, data?.car?.make, data?.car?.model].join(
    " ",
  );

  const transformedData = {
    ...data,
    car_detail: carDetail || "-",
    car_tariff: carTariff || "-",
    car_rating: carRating || "-",
  };

  return { data: transformedData, originalData: data, ...query };
};
