import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { driversService } from "@api/services";
import { DriverStatusesCountQueryParams } from "@common/Types";

type Params = DriverStatusesCountQueryParams & {
  refetchInterval?: number;
};

export const useGetDriverStatusCount = ({
  refetchInterval,
  ...filters
}: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.DRIVER_STATUS_COUNT, filters],
    queryFn: async () => await driversService.getDriverStatusCount(filters),
    refetchInterval,
  });
};
