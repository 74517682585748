import React from "react";

import { CloseBorderedIcon, PencilBorderedIcon } from "@assets/Icons";
import { Button } from "@components/Elements";

type Props = {
  isEdit: boolean;
  onClose: () => void;
  onOpen: () => void;
};

export const EditButtons = ({ isEdit, onClose, onOpen }: Props) => {
  return !isEdit ? (
    <Button type="button" view="none" onClick={onOpen}>
      <PencilBorderedIcon />
    </Button>
  ) : (
    <Button type="reset" view="none" onClick={onClose}>
      <CloseBorderedIcon />
    </Button>
  );
};
