import { FilterTabType, TariffType } from "@common/Types";

export const buildTariffsOptions = (
  data?: TariffType[],
): FilterTabType[] | undefined => {
  return data?.map((tariff) => ({
    label: tariff.name,
    value: tariff.id.toString(),
  }));
};

export const buildTariffFilterOptions = (
  data: TariffType[],
): FilterTabType[] => {
  return data.reduce<FilterTabType[]>((acc, tariff) => {
    if (tariff?.show && tariff.name !== "От борта") {
      acc.push({
        label: tariff.name,
        value: String(tariff.id),
      });
    }
    return acc;
  }, []);
};
