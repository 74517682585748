import React from "react";

export const ImageUploadIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6667 8.66659V13.6499C26.6667 13.6499 23.35 13.6666 23.3333 13.6499V8.66659H18.3333C18.3333 8.66659 18.35 5.34992 18.3333 5.33325H23.3333V0.333252H26.6667V5.33325H31.6667V8.66659H26.6667ZM21.6667 15.3333V10.3333H16.6667V5.33325H3.33333C1.5 5.33325 0 6.83325 0 8.66659V28.6666C0 30.4999 1.5 31.9999 3.33333 31.9999H23.3333C25.1667 31.9999 26.6667 30.4999 26.6667 28.6666V15.3333H21.6667ZM3.33333 28.6666L8.33333 21.9999L11.6667 26.9999L16.6667 20.3333L23.3333 28.6666H3.33333Z"
        fill="#AEB1BB"
      />
    </svg>
  );
};
