import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import React, { useState } from "react";

import { ImageViewer } from "@components/Shared";

export type GalleryPhotos = {
  photos: string[] | undefined;
  grid?: boolean;
};

export const Gallery: React.FC<GalleryPhotos> = ({ photos, grid = false }) => {
  const [currentImg, setCurrentImg] = useState(0);
  const [modalShow, setModalShow] = useState(false);

  const openImageViewer = (index: number) => {
    setModalShow(true);
    setCurrentImg(index);
  };

  return photos ? (
    <div
      className={`${
        grid
          ? "w-full grid grid-cols-2 gap-1"
          : "w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2"
      } mt-4`}
    >
      <ImageViewer
        photos={photos}
        show={modalShow}
        setShow={setModalShow}
        currentImg={currentImg}
        setCurrentImg={setCurrentImg}
      />
      <AnimateSharedLayout>
        <AnimatePresence>
          {photos &&
            photos.map((photo, index) => (
              <motion.img
                key={index}
                src={photo}
                transition={{ ease: "easeOut", duration: 0.3 }}
                onClick={() => openImageViewer(index)}
                animate={{ scale: 1 }}
                alt="drivers photo"
                className="object-cover bg-center w-full rounded-md h-48 cursor-pointer"
                layoutId={`product-${index}`}
              />
            ))}
        </AnimatePresence>
      </AnimateSharedLayout>
    </div>
  ) : (
    <p>Нет фотографий</p>
  );
};
