import {
  Button,
  Group,
  Modal,
  SegmentedControl,
  Space,
  Text,
} from "@mantine/core";
import { RangeCalendar } from "@mantine/dates";
import React, { useEffect, useState } from "react";

import {
  formatOfDate,
  MOBILE_WIDTH,
  separatedFormatOfDate,
} from "@common/Constants";
import { useDimension } from "@common/Hooks";
import { formatDate, formatDateRange } from "@common/Utils";

type Props<T> = {
  filters: T;
  setFilters: (values: T) => void;
  field: keyof T;
  fullWidth?: boolean;
  value?: string;
  separated?: boolean;
};

export const DateRange = <T,>({
  filters,
  field,
  setFilters,
  fullWidth,
  separated,
  value = "today", // if u need choosen date by default to be one of the "today", "yesterday", "week" or "month", u can pass this prop
}: Props<T>) => {
  const { widthD } = useDimension();
  const [chosenRange, setChosenRange] = useState<undefined | string>(
    value ? value : undefined,
  );
  const [opened, setOpened] = useState(false);
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    new Date(),
    new Date(),
  ]);

  useEffect(() => {
    setChosenRange(value ? value : undefined);
  }, [value]);

  const onChange = (range: string) => {
    if (range === "custom") {
      setOpened(true);
      setChosenRange(range);
      return;
    } else {
      const obj = {
        ...filters,
        select_range: range,
        [field]: formatDateRange(range),
      };
      setChosenRange(range);
      setFilters(obj);
    }
  };

  const onSeparatedChange = (range: string) => {
    if (range === "custom") {
      setOpened(true);
      setChosenRange(range);
      return;
    } else {
      setChosenRange(range);
      if (range === "all" && separated) {
        const remaining = {} as T;

        for (const key in filters) {
          if (key !== "start_date" && key !== "end_date") {
            remaining[key] = filters[key];
          }
        }

        setFilters(remaining);
      } else {
        setFilters({
          ...filters,
          start_date: formatDateRange(range, separatedFormatOfDate)[0],
          end_date: formatDateRange(range, separatedFormatOfDate)[1],
        });
      }
    }
  };

  const onCustomChange = (value: [Date, Date]) => {
    if (value.every((date) => date)) {
      setFilters({
        ...filters,
        date_type: "custom",
        [field]: [
          formatDate(value[0], formatOfDate),
          formatDate(value[1], formatOfDate),
        ],
      });
      setDateRange(value);
      setOpened(false);
    }
  };

  const onSeparatedCustomChange = (value: [Date, Date]) => {
    if (value.every((date) => date)) {
      setFilters({
        ...filters,
        start_date: formatDate(value[0], separatedFormatOfDate),
        end_date: formatDate(value[1], separatedFormatOfDate),
      });
      setDateRange(value);
      setOpened(false);
    }
  };

  const options = [
    { label: "Сегодня", value: "today" },
    { label: "Вчера", value: "yesterday" },
    { label: "Неделя", value: "week" },
    { label: "Месяц", value: "month" },
    { label: "Все время", value: "all" },
    {
      label: (
        <Text size={"sm"} color={"gray"} onClick={() => setOpened(true)}>
          Выбрать
        </Text>
      ),
      value: "custom",
    },
  ];

  const isMobile = widthD > MOBILE_WIDTH;

  return (
    <div className={`inline-flex items-center ${fullWidth && "w-full"}`}>
      <SegmentedControl
        size={"sm"}
        value={chosenRange}
        onChange={separated ? onSeparatedChange : onChange}
        fullWidth={fullWidth}
        data={options}
        classNames={{
          labelActive: "h-[32px]",
          root: "h-[40px] bg-[#F2F2F2]",
        }}
      />
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={"Выберите промежуток"}
        className={"justify-center"}
        size={"lg"}
        padding={"xl"}
      >
        <RangeCalendar
          locale="ru"
          nextMonthLabel="Следующий месяц"
          previousMonthLabel="Предыдущий месяц"
          amountOfMonths={isMobile ? 2 : 1}
          value={dateRange}
          onChange={separated ? onSeparatedCustomChange : onCustomChange}
          size={"md"}
          fullWidth
          allowSingleDateInRange
        />
        <Space />
        <Group position={"right"}>
          <Button onClick={() => setOpened(false)}>Закрыть</Button>
        </Group>
      </Modal>
    </div>
  );
};
