/**
 * Сервисы для riders
 */

import { appConfig } from "@api/config";

const src = appConfig.api.dashboard;
const family_account_src = appConfig.api.family_account;

export const ridersPath = {
  riders: `${src}/riders/`,
  clean_riders_debt: (rider_id: string) =>
    `${src}/riders/${rider_id}/clear_debt/`,
  rider_detail: (rider_id: string) => `${src}/riders/${rider_id}/`,
  rider_edit_history: (rider_id?: string) =>
    `${src}/riders/${rider_id}/histories/`,
  rider_bonuses: (rider_id: string) => `${src}/riders/${rider_id}/bonus/`,
  family_account: (rider_id: string) =>
    `${family_account_src}/riders/${rider_id}/`,
  rider_rides: (rider_id: string) => `${src}/riders/${rider_id}/rides/`,
};
