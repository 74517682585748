import React from "react";

import { SVGProps } from "@common/Types";

type Props = SVGProps;

export const WalletIcon = ({ size = 20 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.499 13.4805C13.361 13.4815 13.25 13.5935 13.25 13.7315C13.25 13.8695 13.362 13.9815 13.5 13.9805C13.638 13.9805 13.75 13.8685 13.75 13.7305C13.75 13.5925 13.638 13.4805 13.499 13.4805"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 6.283V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V15C21 16.657 19.657 18 18 18H17"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 9H17"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.325 20.9206C15.205 21.3546 17 19.9266 17 17.9976V9.38558C17 7.98858 16.036 6.77658 14.675 6.46258L5.45 4.33358C4.196 4.04358 3 4.99558 3 6.28158V16.1506C3 17.5476 3.964 18.7596 5.325 19.0736L13.325 20.9206V20.9206Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
