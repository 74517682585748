import { useField } from "formik";
import { isArray } from "radash";
import React from "react";

import { ColorClassKey, CommonFieldParams } from "@common/Types";
import { Flexbox, Switch, Typography } from "@components/Elements";

type Props<T> = {
  params: CommonFieldParams<T>[] | CommonFieldParams<T>;
  isEdit?: boolean;
  bgColor?: ColorClassKey;
};

export const FormSwitchField = <T,>({
  params,
  isEdit = true,
  bgColor = "neutral-50",
}: Props<T>) => {
  const transformedParams = isArray(params) ? params : [params];

  return (
    <>
      {transformedParams.map((limit: CommonFieldParams<T>, index: number) => {
        const { name, field } = limit;
        const [fieldProps, _, helper] = useField(String(field));

        const currentValue = Number(fieldProps.value) ?? 0;
        const { setValue } = helper;

        return (
          <Flexbox
            justifyContent="justify-between"
            bgColor={bgColor}
            className="w-full py-2 px-4 rounded-lg"
            key={`${String(field)}-${index}`}
          >
            <Typography color="neutral-800">{name}</Typography>

            <Switch
              checked={currentValue}
              disabled={!isEdit}
              className="disabled:checked:bg-brand-bg-500"
              onChange={(value: boolean) => setValue(value ? 1 : 0)}
            />
          </Flexbox>
        );
      })}
    </>
  );
};
