import { Button, Group, Modal, Select, Space } from "@mantine/core";
import { RangeCalendar } from "@mantine/dates";
import clsx from "clsx";
import { parse } from "date-fns";
import React, { useEffect, useState } from "react";

import { DATE_FORMAT_dd_MM_yyyy } from "@common/Constants";
import { formatDate } from "@common/Utils";

import { DateRangeFilterType, DateRangeType } from "./types";
import { isDateStringRange } from "./utils";

type predefinedRanges = {
  label: string;
  value: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type Props = {
  maxDate?: Date;
  minDate?: Date;
  format?: string;
  onChangeDate: (dateRange: DateRangeFilterType) => void;
  onChangeSelect: (selectRange: predefinedRanges) => void;
  dateRange?: DateRangeFilterType;
  className?: string;
  predefinedRanges: predefinedRanges[];
  defaultSelected?: predefinedRanges;
};

export const DateRangeSelect: React.FC<Props> = ({
  format = DATE_FORMAT_dd_MM_yyyy,
  dateRange,
  onChangeDate,
  onChangeSelect,
  className,
  predefinedRanges,
  minDate,
  maxDate,
  defaultSelected,
}) => {
  const [opened, setOpened] = useState(false);
  const [selectedValue, setSelected] = useState(defaultSelected?.value);

  const parsedDateRange: DateRangeType = isDateStringRange(dateRange, format)
    ? [
        parse(dateRange[0], format, new Date()),
        parse(dateRange[1], format, new Date()),
      ]
    : [null, null];

  const handlePredefinedChange = (selectedValue: string) => {
    if (selectedValue === "custom") {
      setOpened(true);
    }

    setSelected(selectedValue);
  };

  useEffect(() => {
    if (selectedValue) {
      const selectedItem = predefinedRanges?.find(
        (v) => v.value === selectedValue,
      );
      selectedItem && onChangeSelect(selectedItem);
    }
  }, [selectedValue]);

  const handleDateChange = (range: DateRangeType = [null, null]) => {
    const start = formatDate(range[0], format);
    const end = formatDate(range[1], format);

    onChangeDate([start, end]);

    if (start && end) {
      setOpened(false);
    }
  };

  const list = [...predefinedRanges, { label: "Выбрать", value: "custom" }];

  return (
    <div className={clsx("w-full flex flex-col", className)}>
      <Select
        data={list}
        value={selectedValue}
        onChange={handlePredefinedChange}
        className="h-[40px] min-w-[240px] text-neutral-text-800 font-normal text-body-1-a rounded-[4px] bg-neutral-50"
        classNames={{ input: "bg-[#FAFAFA] text-body-1-a h-[40px]" }}
      />
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Выберите промежуток"
        className="justify-center"
        size="lg"
        padding="xl"
      >
        <RangeCalendar
          allowSingleDateInRange
          minDate={minDate}
          maxDate={maxDate}
          locale="ru"
          nextMonthLabel="Следующий месяц"
          previousMonthLabel="Предыдущий месяц"
          amountOfMonths={2}
          value={parsedDateRange}
          onChange={handleDateChange}
          size="md"
          fullWidth
        />
        <Space />
        <Group position="right">
          <Button onClick={() => setOpened(false)}>Закрыть</Button>
        </Group>
      </Modal>
    </div>
  );
};
