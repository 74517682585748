import { httpClient } from "@common/Libs/axios";
import { AccessPages } from "@common/Types";

import { permissionsPath } from "./paths";

/**
 * Описание методов для permissions.
 */

export const permissionsService = {
  getPermissions: (cityId?: string) =>
    httpClient.get<AccessPages[]>(permissionsPath.permission, {
      params: cityId ? { city_id: cityId } : {},
    }),

  postAccess: (accessList: AccessPages) =>
    httpClient.post(permissionsPath.permission, accessList),
};
