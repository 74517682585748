import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { driversService } from "@api/services";
import { DriverQueryParams } from "@common/Types";

export const useGetDriverStatisticsRide = (params: DriverQueryParams) => {
  return useQuery({
    queryKey: [QUERY_KEYS.DRIVER_STATISTICS_RIDE, params],
    queryFn: async () => await driversService.getDriverStatisticsRide(params),
  });
};
