import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { driversService } from "@api/services";
import { queryClient } from "@common/Libs/react_query";
import { DriverActionsType } from "@common/Types";
import { getAdminActionNameInDriver } from "@common/Utils";

export const useSetDriverAction = () => {
  return useMutation({
    mutationFn: async (params: DriverActionsType) =>
      await driversService.setDriverAction(params),

    onSuccess: (response) => {
      toast.success(getAdminActionNameInDriver(response.action));
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DRIVER_DETAIL] });
    },
  });
};
